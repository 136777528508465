<template >
   <section class="bg-light">
    <div class="container ">
       <div class="d-flex justify-content-center align-items-center wrapper">
            <div class="card bg-dark h-auto shadow p-3 col-lg-6 col-md-9 col-12 mx-auto">
                <div class="invitation-title text-center text-primary">
                    <img src="@/assets/img/modal_logo.png" width="100px" alt="">
                </div>
                <div class="invitation-subtitle text-white text-center mb-2">
                    Thanks for joining us
                </div>
                <div class="h5 text-white text-center mb-5">
                    Click Below to register your account 
                </div>
                <div class="text-center">
                    <button class="btn btn-success">Register</button>
                </div>
            </div>
       </div>
    </div>
   </section>
</template>
<script>
import { useRoute, useRouter } from 'vue-router';
import createNewUser from "@/composables/createNewUser"
    export default {
        name:'Invite',
        setup() {
            const route =  useRoute()
            const email = route.query.email;
            const password = 'test1234'
            const {error, createdUser, createUser} = createNewUser()
            return {
                email,
                password
            }
        },
        methods:{
            async registerUser() {
                // if(email) {
                //     await this.createUser(this.email, this.password)
                //     if(!this.error) {

                //     } else {

                //     }
                // }
            }
        },
        mounted() {
            // console.log(this.$route.query.email)
        }
    } 
</script>
<style>
    .invitation-title {
        font-size: 72px;
        font-weight: bold;
    }
    .invitation-subtitle {
        font-size: 32px;
        font-weight: bold;
    }
    .wrapper {
        min-height: 100vh;
        height: 100%;
    }
</style>