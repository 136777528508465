import { ref } from 'vue';
import { projectAuth, projectFirestore } from '@/firebase/config';
import store from '@/store'
const axios = require('axios');

const error = ref(null)
const user = ref(null)
const login = async (email, password) => {
  error.value = null
  user.value = null

  try {
    // INTERNAL TIMER MAY CAUSE EXTRA ERROR IN THE CONSOLE WHEN USING AWAIT
    // FIREBASE WILL OVERHAUL IN THE FUTURE & EVERYTHING STILL WORKS
    const res = await projectAuth.signInWithEmailAndPassword(email, password)
    // console.log("Response: " + res);
    error.value = null
    projectAuth.onAuthStateChanged(_user => {
      if(_user) {
        user.value = _user.uid
        projectFirestore.collection("Users").doc(_user.uid).get().then((doc) => {
          if(doc.exists) {
            projectFirestore.collection("SupportLibraries").doc(doc.data().support_lib_id).get().then((snap) => {
              if(snap.exists) {
                let url = `https://auth.locationapi.co/resources1?k=${snap.data().library_refkey}`
                axios.get(url).then((res) => {
                    // console.log('url', url)
                    if(res.data.lw == 0) {
                      projectFirestore.collection("SupportLibraries").doc(doc.data().support_lib_id).update({library_only: false})
                    } else if(res.data.lw == 1) {
                      projectFirestore.collection("SupportLibraries").doc(doc.data().support_lib_id).update({library_only: true})
                    } else {
                      projectFirestore.collection("SupportLibraries").doc(doc.data().support_lib_id).update({library_only: true})
                    }
                }).catch((err) => {
                   store.dispatch('printLog', {text:'error',log:err})
                })
              }
            })
          }
        })
      }
    })
    return res
  }
  catch(err) {
    store.dispatch('printLog', {text:'error',log:err})
    error.value = 'Incorrect login credentials'
  }
}

const useLogin = () => {
  return { error, login, user };
}

export default useLogin