<template>
  <!-- @contextmenu="$event.preventDefault()" -->
  <div class="main-container">
    <div class="nav-home">
      <div v-show="setIsNavIconShown()" class="nav-icon-container">
        <i class="fa fa-chevron-left mb-4 nav-icon" @click="handleNavIconClick()" />
      </div>
      <div :class="viewMode === 'all' ? 'nav-item-selected' : 'nav-item nav-item-home'" @click="setViewMode('all');">
        BROWSE HELP LIBRARY
      </div>
      <div v-if="isDoc && isFormOnline" class="customEmailSupportLink"
        :class="viewMode === 'contactUs' ? 'nav-item-selected' : 'nav-item nav-item-home'"
        @click="setViewMode('contactUs');">
        CONTACT US
      </div>
    </div>
    <div v-if="isContentLoading" class="my-5">
      <div v-if="hubLoader !== ''" class="text-center">
        <img :src=hubLoader style="max-width:100%" />
      </div>
      <div v-else>
        <DefaultLoading />
      </div>
    </div>
    <div v-else class="home-main pt-3">
      <div class="row">
        <div v-show="viewMode === 'contactUs'">
          <HomeContactUs :contactFormData="contactFormData" :set-view-mode="setViewMode" v-if="isDoc" />
          <div v-if="!isDoc">
            <h6 class="text-danger">*Invalid Widget Url*</h6>
          </div>
        </div>
        <div v-show="viewMode !== 'contactUs'">
          <div :class="isRTL ? 'me-md-auto' : 'ms-md-auto'">
            <div class="input-group input-group-home">
              <input v-model="searchQuery" type="text" v-on:keyup.enter="searchArticle()"
                class="form-control form-control-home" :placeholder="isRTL ? 'أكتب هنا...' : 'Search our Help Library'" />
              <div class="search-button" @click="searchArticle()">
                <i class="fa fa-search" style="color:black"></i>
              </div>
            </div>
          </div>
        </div>
        <div v-show="viewMode === 'search'">
          <div v-show="prevSearchQuery.split(' ').length <= 1"
            :class="isSearchWarningClosed ? 'search-warning-hidden' : 'search-warning'">
            <div class="warning-close-btn">
              <i class="fa fa-times" @click="closeWarning"></i>
            </div>
            <div>Using only one keyword will lead to irrelevant results. Add more keywords to get more accurate results.
            </div>
          </div>
          <div
            v-if="prevSearchQuery.split(' ').length > 1 && searchQuery !== '' && sortedSearchResults.length == 0 && isSearching == false"
            class="search-warning text-center">No article found related to your searchquery</div>
          <Loading v-if="isSearching"></Loading>
          <!-- <span class="d-none">{{ searchResultLength = searchResults.length }}</span> -->
          <div v-for="article in sortedSearchResults"
            @click="setViewMode('singleArticle'); answerQuestion(''); setArticle(article)" class="search-result">
            <div v-if="isTranslationLoaded(article.article_title)" class="section-title search-title" v-html="highlight(isTranslationLoaded(article.article_title))"></div>
            <Skeleton v-else height="1rem" class="w-100" borderRadius="10px"></Skeleton>
                                        
            <div v-if="isTranslationLoaded(article.article_content)" v-html="highlight(getSearchSubstr(isTranslationLoaded(article.article_content)))" class="search-sub-text"> </div>
            <Skeleton v-else height="1rem" class="w-100" borderRadius="10px"></Skeleton>

          </div>
          <div v-for="(categories, categoryName, index) in allCategoriesAndArticles">
            <div v-if="isTranslationLoaded(categoryName)" v-show="prevSearchQuery && categoryName.toLowerCase().includes(prevSearchQuery.toLowerCase())"
              @click="setViewMode('singleCategory'); setCategory(categoryName)"
              class="section-title search-result search-title" v-html="highlight(isTranslationLoaded(categoryName))">
            </div>
            <Skeleton v-else height="1rem" class="w-100" borderRadius="10px"></Skeleton>
          </div>
        </div>
        <div v-if="viewMode === 'all'">
          <h4 class="category-title help-title">How can we help?</h4>
          <hr color="#E8E8E8" />
          <template v-for="(categories, categoryName, index) in allCategoriesAndArticles">
            <div v-if="isTranslationLoaded(categoryName)" class="section-title" v-on:click="setViewMode('singleCategory'); setCategory(categoryName)">
              <i class="fa fa-chevron-right me-4" style="color: #d5d4d4;"></i>
              {{ isTranslationLoaded(categoryName) }}
            </div>
            <Skeleton v-else height="1rem" class="w-100" borderRadius="10px"></Skeleton>
            <hr />
          </template>
        </div>
        <div v-if="viewMode === 'singleCategory'">
          <h3 v-if="isTranslationLoaded(selectedCategory)" class="text-white1 category-title">{{ isTranslationLoaded(selectedCategory) }}</h3>
          <Skeleton v-else height="1rem" class="w-100" borderRadius="10px"></Skeleton>

          <template v-for="(subCategory, subCategoryName) in allCategoriesAndArticles[selectedCategory]?.subCategory">
            <div v-if="isTranslationLoaded(subCategoryName)" class="section-title" @click="setViewMode('singleSubCategory'); setSubCategory(subCategoryName)">
              <i class="fa fa-chevron-right me-3" style="font-size: 1.4em; line-height: 1.35em; color: #d5d4d4;"></i>
              {{ isTranslationLoaded(subCategoryName) }}
            </div>
            <Skeleton v-else height="1rem" class="w-100" borderRadius="10px"></Skeleton>
            <hr />
          </template>
          <template v-for="article in allCategoriesAndArticles[selectedCategory]?.articles">
            <div v-if="isTranslationLoaded(article.article_title)" class="section-title fw-normal"
              @click="setViewMode('singleArticle'); answerQuestion(''); setArticle(article)">
              <i class="fa fa-file-text-o me-3" style="font-size: 1.4em; line-height: 1.35em; color: #d5d4d4;"></i>
              {{ isTranslationLoaded(article.article_title) }}
            </div>
            <Skeleton v-else height="1rem" class="w-100" borderRadius="10px"></Skeleton>
            <hr />
          </template>
        </div>


        <div v-if="viewMode === 'singleSubCategory'" class="single-article">
          <h3 v-if="isTranslationLoaded(selectedSubCategory)" class="text-white1 category-title">{{ isTranslationLoaded(selectedSubCategory) }}</h3>
          <Skeleton v-else height="1.5rem" class="w-100" borderRadius="10px"></Skeleton>

          <template
            v-for="article in allCategoriesAndArticles[selectedCategory]?.subCategory[selectedSubCategory]?.articles">
            <div v-if="isTranslationLoaded(article.article_title)" class="section-title fw-normal"
              @click="setViewMode('singleArticle'); answerQuestion(''); setArticle(article)">
              <i class="fa fa-file-text-o me-3" style="font-size: 1.4em; line-height: 1.35em; color: #d5d4d4;"></i>
              {{ isTranslationLoaded(article.article_title) }}
            </div>
            <Skeleton v-else height="1rem" class="w-100" borderRadius="10px"></Skeleton>
            <hr />
          </template>
        </div>

        <div v-if="viewMode === 'singleArticle'" class="single-article">
          <div class="d-flex">
            <h3 v-if="isTranslationLoaded(selectedArticle.categoryTitle ? selectedArticle.categoryTitle : selectedCategory)" class="text-white1 category-title mb-2 cursor-pointer fw-normal selected-category-title"
              @click="selectedArticle.categoryTitle ? (viewMode = 'singleCategory', selectedCategory = selectedArticle.categoryTitle) : viewMode = 'singleCategory'">
              {{ isTranslationLoaded(selectedArticle.categoryTitle ? selectedArticle.categoryTitle : selectedCategory) }}
            </h3>
            <Skeleton v-else height="1rem" class="w-100" borderRadius="10px"></Skeleton>

            <h3 class="text-white1 category-title mb-2 cursor-pointer fw-normal selected-category-title"
              v-if="selectedSubCategory !== ''" @click="viewMode = 'singleSubCategory'">
              <template v-if="isTranslationLoaded(selectedCategory)"> &nbsp; >> &nbsp; {{ isTranslationLoaded(selectedCategory)  }}   </template>
              <Skeleton v-else height="1rem" class="w-100" borderRadius="10px"></Skeleton>
            </h3>

          </div>
          <h2 v-if="isTranslationLoaded(selectedArticle.article_title)" class="article-title mt-2">{{ isTranslationLoaded(selectedArticle.article_title) }}</h2>
          <Skeleton v-else height="1.5rem" class="w-100" borderRadius="10px"></Skeleton>

          <div class="article-content">
            <div v-if="isTranslationLoaded(selectedArticle.article_content)" v-html="isTranslationLoaded(selectedArticle.article_content)"></div>
            <Skeleton v-else height="5rem" class="w-100" borderRadius="10px"></Skeleton>
          </div>
          <HomeFeedback :id="selectedArticle.id" :answer="answer" @answerQuestion="answerQuestion" :set-view-mode="setViewMode" />
        </div>
      </div>
    </div>
    <!-- <app-footer /> -->
  </div>
</template>
<script>
import '@/assets/scss/home.scss';
import { useRoute, useRouter } from 'vue-router';
import fgetUserAndHlptCategoriesAndArticles from "@/composables/front/fgetUserAndHlptCategoriesAndArticles";
import AppFooter from "@/components/custom/Footer.vue";
import Loading from "@/components/custom/Loading.vue";
import HomeContactUs from "../components/HomeContactUs.vue";
import HomeFeedback from '../components/HomeFeedback.vue';
import { getUser, lastarticleId } from "@/composables/getUser"
import getCustomCss from "@/composables/getCustomCss"
import getIntegrations from "@/composables/getIntegrations"
import getUserActiveSupportCategories from '@/composables/getUserActiveSupportCategories'
import { projectFirestore, timestamp } from '@/firebase/config'
import firebase from 'firebase/app'
import getUserInfo from "@/composables/getUserInfo"
import DefaultLoading from "@/components/custom/DefaultLoading.vue";
import moment from "moment";
import { useStore } from "vuex";
import { ref } from 'vue';
import getTranslations from '@/composables/getTranslations'
import _ from 'lodash';
import { getSortedSearchResults } from '@/composables/front/searchUtils';
import getUserCategoryPositioning from '@/composables/getUserCategoryPositioning';
import Api from '../../admin/Api';
const axios = require('axios');

export default {
  name: "Home",
  components: {
    AppFooter,
    Loading,
    HomeContactUs,
    HomeFeedback,
    DefaultLoading
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const library_key = route.params.library_key;
    const default_support_lib_id = ref('');
    const session_id = route.params.session_id;
    const isContentLoading = ref(true);
    const hubLoader = ref('')
    const parentURL = route.query.parentURL ? route.query.parentURL : document.referrer;

    const {
      allCategoriesAndArticles,
      load_articles_and_categories,
      prevSearchQuery,
      searchArticles
    } = fgetUserAndHlptCategoriesAndArticles();


    const { fetchTranslation, translations } = getTranslations()
    
    const { getCategoryPositioning, categoryPositioning, updateError } = getUserCategoryPositioning()
    // console.log('allCategoriesAndArticles', allCategoriesAndArticles)

    const { isCustomCss } = getCustomCss()
    const { isIntegrations } = getIntegrations()

    const initData = async (library_key) => {
      await getUserCategoryPositioning(library_key)
      await projectFirestore.collection('SupportLibraries').doc(library_key).get().then(async (doc) => {
        if (doc.exists) {
          const parent_lib_id = doc.data()?.parent_lib_id ? doc.data()?.parent_lib_id : '';
          default_support_lib_id.value = parent_lib_id;
          store.state.parent_lib_id = parent_lib_id
          document.body.setAttribute('parent_lib_id', parent_lib_id)
          localStorage.setItem("parent_lib_id", parent_lib_id)

          await load_articles_and_categories(library_key);
          await isCustomCss(library_key, 'widget')
          await isIntegrations(library_key, 'widget')
          await fetchTranslation(library_key)

          // console.log('store.state.parent_lib_id',store.state.parent_lib_id)
          let ticketLocationUrl = parentURL;
          if (doc.data()?.chatIntegrations?.enable == true) {
            if (window.LiveChatWidget) {
              LiveChatWidget.init();
              // LiveChatWidget.call("maximize");
              LiveChatWidget.on('visibility_changed', (event) => {
                // console.log('max', event)
                if (event.visibility == "maximized") {
                  LiveChatWidget.call("set_session_variables", {
                    "Started on": ticketLocationUrl,
                  });
                }
              })
            }
          }

          const title = document.getElementById("ogTitle");
          const description = document.getElementById("ogDescription");
          const keywords = document.getElementById("ogKeywords");
          const favicon = document.getElementById("favicon");
          if (doc.data().settings?.metaData !== undefined && doc.data().settings?.metaData.title) {
            if (title) {
              document.title = doc.data().settings.metaData.title
              title.setAttribute("content", doc.data().settings.metaData.title);
            }
          } else {
            document.title = 'Modal Support'
            title.setAttribute("content", 'Modal Support')
          }
          if (doc.data().settings?.metaData !== undefined && doc.data().settings.metaData.description) {
            if (description) {
              description.setAttribute("href", doc.data().settings.metaData.description);
            }
          }
          if (doc.data().settings?.metaData !== undefined && doc.data().settings.metaData.keywords) {
            if (keywords) {
              keywords.setAttribute("href", doc.data().settings.metaData.description);
            }
          }
          if (doc.data().publicHelpCenterTheme && doc.data().publicHelpCenterTheme.favicon) {
            favicon.setAttribute("href", doc.data().publicHelpCenterTheme.favicon);
          } else {
            favicon.setAttribute("href", '/favicon.ico');
          }
        }
      })
    }

    initData(library_key)

    // console.log('library_key', library_key)

    // setTimeout(() => {
    //   isContentLoading.value = false;
    // }, 2500);

    return {
      allCategoriesAndArticles,
      load_articles_and_categories,
      prevSearchQuery,
      library_key,
      store,
      default_support_lib_id,
      hubLoader,
      isContentLoading,
      translations,
      parentURL,
      searchArticles
    };
  },
  data() {
    return {
      searchResults: [],
      viewMode: 'all',
      selectedCategory: '',
      selectedSubCategory: '',
      selectedArticle: {},
      searchQuery: '',
      isSearching: false,
      isSearchWarningClosed: false,
      answer: '',
      isSaving: false,
      support_lib_id: '',
      qualifyUserQuestion: {},
      searchStep: {},
      steps: {},
      formSubmission: {},
      contactFormData: {},
      isDoc: false,
      stats: {},
      searchResultLength: 0,
      successArticleSearchId: '',
      isFormOnline: false,
      isSelfManaged: false,
      library_only: false
    };
  },
  computed: {
    isRTL() {
      return false
    },
    sortedSearchResults() {
      return getSortedSearchResults(this.searchResults, this.searchQuery);
    },
  },
  methods: {
    setViewMode(view) {
      this.viewMode = view;
    },
    setCategory(category) {

      this.selectedCategory = category
      // console.log('sss', this.allCategoriesAndArticles[category])
    },
    setSubCategory(subCategory) {
      this.selectedSubCategory = subCategory;
    },
    setArticle: async function (article) {
      this.selectedArticle = article;
      this.$store.dispatch('updateLastSuccessArticleSearch', { data: this.selectedArticle.id })

    },
    setWidgetViews: async function () {
      let payload = {
        supportLibId:this.library_key,
        firestoreObj: {
          timestamp:  moment.utc().valueOf()
        }
      }
      await Api.addWidgetActivity(payload).then(() => {
      }).catch((err) => {
        this.$store.dispatch('printLog', {text:'an error occured while add widget views', log:err})
      })
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    setSearches: async function () {
      let status = '';
      setTimeout(async () => {
        this.$store.dispatch('updateSearchedKeyword', this.searchQuery)
        if (this.searchResults.length > 0) {
          status = 'passed';
        } else {
          status = 'failed';
        }
        // conso

        let payload  = {
          supportLibId :  this.$route.params.library_key,
          firestoreObj : {
              articlesReadId: [],
              articlesReadTitle: [],
              timestamp:  moment.utc().valueOf(),
              reviewed:true,
              searchQuery: this.searchQuery,
              status:status,
              ticket: 0,
          }
        }
        Api.addSearchActivity(payload).then((res)=> {
            // this.$store.dispatch('printLog', { text:'addSearchActivity success', log: res })
          if(res?.data) {
              this.successArticleSearchId = res.data?.searchId
              if (status == 'passed') {
                  this.$store.dispatch('updateLastFailedArticleSearch', { data: '' })
              } else {
                  this.$store.dispatch('updateLastFailedArticleSearch', { data:  this.successArticleSearchId})
              }
          }
        }).catch((error) => {
            this.$store.dispatch('printLog', { text:'An error occured while adding search views', log: error })
        }).finally(() => {
          this.isSearching = false
        })
        
      }, 1500);
    },
    async searchArticle() {
      if (this.searchQuery.trim() !== '') {
        // console.log(this.lastarticleId)
        this.isSearchWarningClosed = false;
        this.setViewMode('search');
        this.searchResults = [];
        this.isSearching = true;
        // console.log('fetching', this.library_key)
        // console.log('fetching', this.searchQuery)
        this.searchArticles(this.library_key, this.searchQuery).then((res) => {
          this.searchResults = _.uniqBy(res, 'article_title');
        }).catch((error) => {
          this.$store.dispatch('printLog', {text:'Error in search:', log:error});
        }).finally(() => {
          this.setSearches()
        });
      } else {
        this.setViewMode('all')
      }
    },
    setIsNavIconShown() {
      return this.viewMode === 'singleCategory' || this.viewMode === 'singleSubCategory' || this.viewMode === 'singleArticle' || this.viewMode === 'search';
    },
    handleNavIconClick() {
      if (this.viewMode === 'search') {
        this.setViewMode('all');
        this.searchQuery = '';
      }
      if (this.viewMode === 'singleCategory') {
        this.setViewMode(this.searchQuery ? 'search' : 'all');
      }
      if (this.viewMode === 'singleSubCategory') {
        this.setViewMode(this.searchQuery ? 'search' : 'singleCategory');
      }
      if (this.viewMode === 'singleArticle') {
        this.setViewMode(this.searchQuery ? 'search' : 'singleCategory');
      }
    },
    getSearchSubstr(content) {
      let splitContent = content.split('. ');

      let filteredContent = splitContent.filter(sentence => sentence.includes(this.searchQuery));

      if (filteredContent.length) {
        return filteredContent[0].replace(/<[^>]*>?/gm, '').slice(0, 100);
      }
      else {
        return splitContent[0].replace(/<[^>]*>?/gm, '').slice(0, 100);
      }
    },
    highlight(content) {
      if (!this.prevSearchQuery) return content;
      return content.replace(new RegExp(this.prevSearchQuery, "gi"), match => {
        return '<span class="highlighted">' + match + '</span>';
      });
    },
    closeWarning() {
      this.isSearchWarningClosed = true;
    },
    answerQuestion: async function (answer) {
      // console.log('answer', answer)
      this.answer = answer;
      if (this.selectedArticle.id && answer !== '') {
        let article_ref = projectFirestore.collection('Articles').doc(this.selectedArticle.id)
        if (answer == 'yes') {
            await article_ref.update({likes:firebase.firestore.FieldValue.increment(1)})
        } else {
            await article_ref.update({disLikes:firebase.firestore.FieldValue.increment(1)})
        }
      }
    },
    handleFormData() {
      this.contactFormData = {
        qualifyUserQuestion: this.qualifyUserQuestion,
        searchStep: this.searchStep,
        steps: this.steps,
        formSubmission: this.formSubmission
      }
    },
    getDefaultContactForm: async function () {
      // console.log('getDefaultContactForm',)
      if (this.default_support_lib_id) {
        await projectFirestore.collection('SupportLibraries').doc(this.default_support_lib_id).get().then((doc) => {
          if (doc.exists) {
            if (doc.data()?.contactForm) {
              this.isDoc = true
              this.isFormOnline = true
              this.qualifyUserQuestion = doc.data().contactForm.qualifyUserQuestion,
                this.searchStep = doc.data().contactForm.searchStep,
                this.steps = doc.data().contactForm.steps,
                this.formSubmission = doc.data().contactForm.formSubmission
              this.handleFormData();
            }
          } else {
            // console.log('For does not exist')
            this.isDoc = false;
          }

          if (this.$route.query.hc == 1) {
            this.isDoc = false
          }
        })
      }
    },
    getContactForm: async function () {
      this.isSaving = true;
      this.support_lib_id = this.$route.params.library_key;
      await projectFirestore.collection('SupportLibraries').doc(this.library_key).get().then(async (doc) => {
        if (doc.exists) {
          let url = `https://auth.locationapi.co/resources1?k=${doc.data().library_refkey}`;

          axios.get(url).then(async (res) => {
            {
              // console.log('res.data.lw', res.data.lw)
              if (res.data.lw == 1) {
                this.library_only = true
              } else {
                this.library_only = false
              }
            }

            //alert(this.support_lib_id)
            await projectFirestore.collection('SupportLibraries').doc(this.library_key).get().then((doc) => {
              if (doc.exists) {
                if (this.library_only == false) {
                  if (doc.data().settings && doc.data().settings.ticket?.selfManaged !== undefined && doc.data().settings.ticket?.selfManaged == true && doc.data()?.contactForm) {
                    this.isDoc = true;
                    if (doc.data()?.isContactFormOnline == undefined || doc.data()?.isContactFormOnline !== false) {
                      this.isFormOnline = doc.data()?.isContactFormOnline
                    }
                    this.qualifyUserQuestion = doc.data().contactForm.qualifyUserQuestion,
                      this.searchStep = doc.data().contactForm.searchStep,
                      this.steps = doc.data().contactForm.steps,
                      this.formSubmission = doc.data().contactForm.formSubmission
                    this.handleFormData();
                  } else if (doc.data().settings && doc.data().settings.ticket?.selfManaged !== undefined && doc.data().settings.ticket?.selfManaged == false) {
                    this.getDefaultContactForm()
                    if (doc.data()?.isContactFormOnline == undefined || doc.data()?.isContactFormOnline !== false) {
                      this.isFormOnline = doc.data()?.isContactFormOnline
                    }
                  } else {
                    this.getDefaultContactForm()
                  }
                } else if (this.library_only == true) {
                  if (doc.data()?.contactForm) {
                    this.isDoc = true;
                    if (doc.data()?.isContactFormOnline == undefined || doc.data()?.isContactFormOnline !== false) {
                      this.isFormOnline = doc.data()?.isContactFormOnline
                    }
                    this.qualifyUserQuestion = doc.data().contactForm.qualifyUserQuestion,
                      this.searchStep = doc.data().contactForm.searchStep,
                      this.steps = doc.data().contactForm.steps,
                      this.formSubmission = doc.data().contactForm.formSubmission
                    this.handleFormData();
                  }
                  // else {
                  //   this.getDefaultContactForm()
                  // }
                }
                // if (doc.data()?.isContactFormOnline !== undefined) {
                //   this.isFormOnline = doc.data()?.isContactFormOnline
                // }
              }
            })
            this.isSaving = false;
            if (this.$route.query.hc == 1) {
              this.isDoc = false
            }
          }).catch((err) => {
            this.$store.dispatch('printLog', {log:'error', text:err})
          })

        } else {
          // console.log('doc not exists');
        }
      })
    },

    isTranslationLoaded(dataElement) {
        if (dataElement) {
            let isReplaced = false;
            
            this.translations.forEach((elem, i) => {
            const searchRegExp = new RegExp(`\\b${elem.source}\\b`, 'gi');
                dataElement = dataElement.replaceAll(searchRegExp, elem.translation);
                if(i ==  this.translations.length - 1) {
                    isReplaced = true;
                }
            });
            
            return isReplaced ? dataElement : null;
        }
    }
  },
  beforeMount() {
    /* const router = useRouter();
    if(!this.$route.params.session_id){
      console.log("Session ID not available. Redirecting...");
      router.push({ name: 'Home_Session', params: { library_key: this.$route.params.library_key, session_id: "1232" } });
    } else {
      console.log("Session ID: " + this.$route.params.session_id);
    } */
  },
  mounted() {

    // console.log(this.lastarticleId)
    // let parentURL = this.$route.query.parentURL
    // let url = parentURL ? parentURL : document.location.href
    // console.log('url', url)
    // document.querySelector('meta[property="og:url"]').setAttribute("content", url);
  },
  watch: {
    viewMode: async function () {
      this.scrollToTop()
      if (this.viewMode == 'singleArticle') {

        let payload  = {
          supportLibId:this.library_key,
          firestoreObj:{
            articleId: this.selectedArticle.id,
            articleTitle: this.selectedArticle.article_title,
            timestamp:  moment.utc().valueOf()
          }
        }
        await Api.addArticleActivity(payload).then(async(res) => {
          try {
            const articleRef = projectFirestore.collection("Articles").doc(this.selectedArticle.id);
            const res = await articleRef.get();
            if (res.exists) {
              await articleRef.update({
                views: firebase.firestore.FieldValue.increment(1) // Increment view count safely
              });
            }
          } catch (error) {
            this.$store.dispatch('printLog', {text:"Error updating article views:", log:error});
          }
        }).catch((err) =>{
          this.$store.dispatch('printLog', {text:'An error occured while adding article view', log:err})
        })

        if(this.successArticleSearchId) {
          let updateSearchActivityPayload = {
            id:this.successArticleSearchId,
            article_read_id:this.selectedArticle.id,
            article_read_title:this.selectedArticle.article_title ?? ''
          }
          Api.updateSearchActivity(updateSearchActivityPayload).then(() => {
          }).catch((err) => {
            store.dispatch('printLog', {text:'err while updating search activity', log:'err'})
          })
        }

      }
    },
    translations() {
      if (this.translations.length) {
        this.isContentLoading = false
      }
    },
    default_support_lib_id() {
      // console.log('default_support_lib_id', this.default_support_lib_id)
      if (this.default_support_lib_id !== null) {
        this.getContactForm();
        this.setWidgetViews();
      }
    }
  }
};
</script>
<style>
/* #chat-widget-minimized {
  display: unset !important;
}
.tawk-min-container {
    display: unset !important;
} */
</style>