/*
 * Used to display a combined list of the user categories created by the user himself and the hlpt support team
 * So the list shows the list of all the categories available to any specific user
 */
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { projectFirestore } from '../firebase/config';
import { useStore } from 'vuex';

const getUserActiveSupportCategories = (user_id) => {
  // console.log('user_id ', user_id)
  const router = useRouter();
  const store = useStore()
  const hlpt_support_library_id = store.state.parent_lib_id;
  const active_categories_user_info = ref(null);
  const user_active_support_library_details = ref(null);
  const user_active_support_categories = ref(null);
  const user_active_support_categories_default_enabled_object = ref(null);
  const user_active_support_categories_default_enabled = ref([]);
  const active_support_categories_error = ref(null);
  const disabled_default_categories = ref(null);
  const active_support_category_ids = ref([]);
  const active_support_categories_list = ref({});
  const user_info_support_lib_id = ref(null);

  const load_user_active_support_categories = async () => {
    try {
      projectFirestore
        .collection('Users')
        .doc(user_id)
        .get()
        .then((doc) => {
          if (doc.exists) {
            // console.log('Pt 3');
            //Document data:", doc.data());
            active_categories_user_info.value = doc.data();
            user_info_support_lib_id.value = active_categories_user_info.value.support_lib_id;
            //disabled_default_categories.value = active_categories_user_info.value.disabled_default_categories;
            // console.log('User Info Support Lib  asc ID: ' + JSON.stringify(active_categories_user_info.value));
            if (user_info_support_lib_id.value) {
              // console.log('Support Library ID asc: ' + user_info_support_lib_id.value);

              projectFirestore
                .collection('SupportLibraries')
                .doc(user_info_support_lib_id.value)
                .get()
                .then((doc) => {
                  user_active_support_library_details.value = { ...doc.data(), id: doc.id };
                  // console.log('Support Library Details: ' + JSON.stringify(user_active_support_library_details));

                  disabled_default_categories.value =
                    user_active_support_library_details.value.disabled_default_categories;

                  // console.log('Disabled Categories Length: ' + disabled_default_categories.value);
                  let categoryTableIds = []
                  if(hlpt_support_library_id) {
                    categoryTableIds = [hlpt_support_library_id, user_info_support_lib_id.value]
                  } else {
                    categoryTableIds = [user_info_support_lib_id.value]
                  }
                  projectFirestore
                    .collection('Categories')
                    .where('support_lib_id', 'in', categoryTableIds)
                    .where('is_enabled', '==', true)
                    .orderBy('category_title', 'asc')
                    .get().then((snap) => {

                      let docs = snap.docs.map((doc) => {
                        var doc_id = doc.id;
                        var is_default_enabled = true;
                        if (disabled_default_categories.value && disabled_default_categories.value.includes(doc_id)) {
                          is_default_enabled = false;
                        } else {
                          active_support_category_ids.value.push(doc.id);
                          var active_support_cat_title = doc.data().category_title;
                          active_support_categories_list.value[doc.id] = active_support_cat_title;
                        }
                        return { ...doc.data(), id: doc.id, is_default_enabled: is_default_enabled };
                      });
                      user_active_support_categories.value = docs;
                      // console.log('User Active Support Categories: ' + user_active_support_categories);

                      //console.log("Snap Docs: " + JSON.stringify(snap.docs));

                      let docs_default_enabled = snap.docs.map((doc_default_enabled) => {
                        // console.log('Doc default enabled: ' + JSON.stringify(doc_default_enabled.id));
                        var doc_id_default_enabled = doc_default_enabled.id;
                        var is_default_enabled2 = true;
                        if (disabled_default_categories.value && !disabled_default_categories.value.includes(doc_id_default_enabled)) {
                          // console.log('Doc ID default enabled: ' + doc_default_enabled.id);
                          return { ...doc_default_enabled.data(), id: doc_default_enabled.id };
                        }
                      });
                      user_active_support_categories_default_enabled_object.value = docs_default_enabled;

                      for (const property in user_active_support_categories_default_enabled_object.value) {
                        var property_string = JSON.stringify(
                          user_active_support_categories_default_enabled_object.value[property],
                        );
                        if (typeof property_string != 'undefined') {
                          // console.log('Property STring: ' + property_string);
                          var property_string_obj = JSON.parse(property_string);
                          user_active_support_categories_default_enabled.value.push(property_string_obj);
                        }
                      }
                    });
                });
            }
          } else {
            // doc.data() will be undefined in this case
             store.dispatch('printLog', {log:'No such user!'});
             router.push({ name: 'Login' })
          }
        })
        .catch((error) => {
          store.dispatch('printLog', {text:'Error getting document:', log:error});
        });
    } catch (err) {
      active_support_categories_error.value = err.message;
    }
  };
  return {
    active_categories_user_info,
    active_support_categories_error,
    user_active_support_categories,
    load_user_active_support_categories,
    active_support_category_ids,
    active_support_categories_list,
    user_active_support_categories_default_enabled,
    user_info_support_lib_id,
  };
};
export default getUserActiveSupportCategories;
