<template>
    <div style="max-width: 125px;"
        class="position-absolute text-xs rounded text-xs companydomain text-nowrap text-ellipsis">
        {{ getDomain() }}</div>
</template>
<script>
export default {
    name: 'DomainLabel',
    methods: {
        getDomain() {
            return localStorage.getItem('library_domain');
        },
    }
}
</script>