import { ref } from 'vue';
import { projectAuth } from '../firebase/config';
import router from '../router';
import firebase from 'firebase/app'
import 'firebase/auth'
import { useStore } from "vuex"

const error = ref(null)
const store = useStore
const passwordResetLink = async (email) => {
    error.value = null
    await firebase.auth().sendPasswordResetEmail(email).then(() => {
        error.value = null
    }).catch((err) => {
        store.dispatch('printLog', {log:err.message})
        error.value = 'Error while sending link'
    });
}

const sendPasswordResetLink = () => {
  return { error, passwordResetLink};
}

export default sendPasswordResetLink