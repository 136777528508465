import { ref } from 'vue'
import router from '@/router'
import store from '@/store'
import { projectAuth } from '../firebase/config'

// refs
const error = ref(null)

// logout function
const logout = async () => {
  error.value = null


  try {
    const favicon = document.getElementById("favicon");
    const title = document.getElementById("ogTitle");
    const description = document.getElementById("ogDescription");
    const keywords = document.getElementById("ogKeywords");
    if (favicon) {
      favicon.setAttribute("href", "data:image/x-icon;base64,AA");
    }
    if (title) {
      title.setAttribute("content", " ");
    }
    if (description) {
      description.setAttribute("href", " ");
    }
    if (keywords) {
      keywords.setAttribute("href", " ");
    }
    await projectAuth.signOut();
    router.push({ name: 'Login' })

    localStorage.removeItem('sessionTimeout');
    localStorage.removeItem('support_Lib_id');
    localStorage.removeItem('parent_lib_id');
    localStorage.removeItem('library_domain');
    localStorage.removeItem('user_email');
    localStorage.removeItem("company_name");
    localStorage.removeItem('user_type');
    localStorage.removeItem('user_id');
    localStorage.removeItem('isConnectionEnabled')
    localStorage.removeItem('agent_connection_queue_ref');
    localStorage.removeItem('agent_connection_device_ref');
    localStorage.removeItem('agent_connection_visitor_ref');
    localStorage.removeItem('agent_connection_agent_ref');
    localStorage.removeItem('agent_connection_agent_con_id');
    localStorage.removeItem('agent_connection_visitor_con_id');
    localStorage.removeItem('agent_connection_conversation_ref');
    localStorage.removeItem('inbox-chat-token');
    localStorage.removeItem('token-time')
    // let reportBugWidget =  document.getElementById('reportBugWidget');
    // let reportBugWidgetBtn = document.getElementsByClassName('videoask-embed__button--SgLKO')[0]
    // if(reportBugWidget) {
    //   reportBugWidget.remove()
    // }
    // if(reportBugWidget) {
    //   reportBugWidgetBtn.remove()
    // }
    // console.log('store.state.parent_lib_id',store.state.parent_lib_id)
    // console.log('logout works')
    if (store.state.parent_lib_id) {
      store.state.parent_lib_id = ''
    }


    store.state.support_lib_id = '';
    store.state.user_id = '';
    store.state.agent_picture = '';
    store.state.frontendUnreadMsgs = 0;
    store.state.queueMembers = [];
    store.state.inboxMembers = [];
    store.state.messages = {};
    store.state.selectedChatBefore = null;
    store.state.selectedChat = null;
    store.state.selectedOngoingChat = null;
    store.state.socket = '';
    store.state.scrollToBottom = false;
    store.state.display_name = '';
    store.state.isticketCreating = false;
    store.state.isticketTypeCreating = false;
    store.state.isDirectTicketCreating = false;

    store.state.selectedArchive = null;
    store.state.isArchiveSearch = false;
    store.state.searchArchive = false;
    store.state.isArchiveSearching = false;
    store.state.archiveSortByFilter = '';
    store.state.archiveEmailFilter = '';
    store.state.archivePerPage = 20;
    store.state.allChatsSortByFilter = '';
    store.state.archiveChatsTotalRecords = 0;
    store.state.archiveChatsPage = 1;
    store.state.archiveChats = [];
    store.state.inboxChatsType = 'all';
    store.state.isDisplayInboxChats = true;
    store.state.isDisplaySuperviseChats = true;

    store.state.superviseChats = {};
    store.state.allOnGoingChats = [];
    store.state.isSuperviseChatsLoading = true;
    store.state.isAllOnGoingChatsLoading = true;

    store.state.selectedTicket = null;
    store.state.ticketsTotalRecords = 0;
    store.state.ticketsPage = 1;
    store.state.isTicketSearch = false;
    store.state.searchTicket = false;
    store.state.isTicketSearching = false;
    store.state.ticketSortByFilter = '';
    store.state.ticketEmailFilter = '';
    store.state.ticketStatusByFilter = '';
    store.state.ticketCategoryFilter = '';
    store.state.ticketTierFilter = '';
    store.state.ticketCreateMethodFilter = '';
    store.state.ticketRefFilter = '';
    store.state.ticketCreateStatusFilter = '';
    store.state.tickets = [];

    store.state.selectedOtherTicket=null;
    store.state.otherTicketsTotalRecords=0;
    store.state.otherTicketsPage=1;
    store.state.isOtherTicketSearch=false;
    store.state.searchOtherTicket=false;
    store.state.isOtherTicketSearching=false;
    store.state.otherTicketSortByFilter='newest';
    store.state.otherTicketEmailFilter='';
    store.state.otherTicketStatusByFilter='';
    store.state.otherTicketCategoryFilter='';
    store.state.otherTicketTierFilter='';
    store.state.otherTicketCreateMethodFilter='';
    store.state.otherTicketRefFilter='';
    store.state.otherTicketCreateStatusFilter='';
    store.state.otherTicketsPerpage=20;
    store.state.otherTickets=[];
    store.state.fetchOtherTickets=false;
    store.state.isLoadingOtherTickets=false;

    store.state.selectedSearch = {};
    store.state.searchTotalRecords = 0;
    store.state.searchPage = 1;
    store.state.isSearch = false;
    store.state.searchTicket = false;
    store.state.isSearching = false;
    store.state.searchSortByFilter = '';
    store.state.searchEmailFilter = '';
    store.state.searchStatusByFilter = '';
    store.state.searchCategoryFilter = '';
    store.state.searchTagFilter = '';
    store.state.searchTierFilter = '';
    store.state.searchCreateMethodFilter = '';
    store.state.searchTicketRefFilter = '';
    store.state.searchCreateStatusFilter = '';
    store.state.searchRecords = [];
    store.state.searchCompanyFilter = null;
    store.state.searchCtFromFilter = '';
    store.state.searchCtToFilter = '';
    store.state.filterSearchCompanies = '';
    store.state.searchDateFilter = '';
    store.state.searchFilterType = '';
    store.state.searchTicketConversationRefFilter = '';
    store.state.searchQuery = '';



    store.state.selectedMentionTicket=null;
    store.state.isSelectedMentionTicketLoading=false;
    store.state.mentionTicketsTotalRecords=0;
    store.state.mentionTicketsPage=1;
    store.state.isMentionTicketSearch=false;
    store.state.searchMentionTicket=false;
    store.state.isMentionTicketSearching=false;
    store.state.mentionTicketSortByFilter='newest';
    store.state.mentionTicketEmailFilter='';
    store.state.mentionTicketStatusByFilter='';
    store.state.mentionTicketCategoryFilter='';
    store.state.mentionTicketTierFilter='';
    store.state.mentionTicketCreateMethodFilter='';
    store.state.mentionTicketRefFilter='';
    store.state.mentionTicketCreateStatusFilter='';
    store.state.mentionTicketsPerpage=20;
    store.state.mentionTickets=[];
    store.state.mentionFilterBy='unread';
    store.state.fetchMentionTickets=false;
    store.state.isLoadingMentionTickets=false;

    store.state.selectedCreatedByYouTicket=null;
    store.state.createdByYouTicketsTotalRecords=0;
    store.state.createdByYouTicketsPage=1;
    store.state.isCreatedByYouTicketSearch=false;
    store.state.searchCreatedByYouTicket=false;
    store.state.isCreatedByYouTicketSearching=false;
    store.state.createdByYouTicketSortByFilter='newest';
    store.state.createdByYouTicketEmailFilter='';
    store.state.createdByYouTicketStatusByFilter='';
    store.state.createdByYouTicketCategoryFilter='';
    store.state.createdByYouTicketTierFilter='';
    store.state.createdByYouTicketCreateMethodFilter='';
    store.state.createdByYouTicketRefFilter='';
    store.state.createdByYouTicketCreateStatusFilter='';
    store.state.createdByYouTicketsPerpage=20;
    store.state.createdByYouTickets=[];
    store.state.fetchCreatedByYouTickets=false;
    store.state.isLoadingCreatedByYouTickets=false;


    store.state.selectedReassignedTicket=null;
    store.state.reassignedTicketsTotalRecords=0;
    store.state.reassignedTicketsPage=1;
    store.state.isReassignedTicketSearch=false;
    store.state.searchReassignedTicket=false;
    store.state.isReassignedTicketSearching=false;
    store.state.reassignedTicketSortByFilter='newest';
    store.state.reassignedTicketEmailFilter='';
    store.state.reassignedTicketStatusByFilter='';
    store.state.reassignedTicketCategoryFilter='';
    store.state.reassignedTicketTierFilter='';
    store.state.reassignedTicketCreateMethodFilter='';
    store.state.reassignedTicketRefFilter='';
    store.state.reassignedTicketCreateStatusFilter='';
    store.state.reassignedTicketsPerpage=20;
    store.state.reassignedTickets=[];
    store.state.fetchReassignedTickets=false;
    store.state.isLoadingReassignedTickets=false;

    store.state.selectedUnassignedTicket=null;
    store.state.unassignedTicketsTotalRecords=0;
    store.state.unassignedTicketsPage=1;
    store.state.isUnassignedTicketSearch=false;
    store.state.searchUnassignedTicket=false;
    store.state.isUnassignedTicketSearching=false;
    store.state.unassignedTicketSortByFilter='newest';
    store.state.unassignedTicketEmailFilter='';
    store.state.unassignedTicketStatusByFilter='';
    store.state.unassignedTicketCategoryFilter='';
    store.state.unassignedTicketTierFilter='';
    store.state.unassignedTicketCreateMethodFilter='';
    store.state.unassignedTicketRefFilter='';
    store.state.unassignedTicketCreateStatusFilter='';
    store.state.unassignedTicketsPerpage=20;
    store.state.unassignedTickets=[];
    store.state.fetchUnassignedTickets=false;
    store.state.isLoadingUnassignedTickets=false;

    store.state.selectedAwaitingReplyTicket=null;
    store.state.awaitingReplyTicketsTotalRecords=0;
    store.state.awaitingReplyTicketsPage=1;
    store.state.isAwaitingReplyTicketSearch=false;
    store.state.searchAwaitingReplyTicket=false;
    store.state.isAwaitingReplyTicketSearching=false;
    store.state.awaitingReplyTicketSortByFilter='newest';
    store.state.awaitingReplyTicketEmailFilter='';
    store.state.awaitingReplyTicketStatusByFilter='';
    store.state.awaitingReplyTicketCategoryFilter='';
    store.state.awaitingReplyTicketTierFilter='';
    store.state.awaitingReplyTicketCreateMethodFilter='';
    store.state.awaitingReplyTicketRefFilter='';
    store.state.awaitingReplyTicketCreateStatusFilter='';
    store.state.awaitingReplyTicketsPerpage=20;
    store.state.awaitingReplyTickets=[];
    store.state.fetchAwaitingReplyTickets=false;
    store.state.isLoadingAwaitingReplyTickets=false;

    store.state.selectedAwaitingReplyHlptTicket=null;
    store.state.awaitingReplyHlptTicketsTotalRecords=0;
    store.state.awaitingReplyHlptTicketsPage=1;
    store.state.isAwaitingReplyHlptTicketSearch=false;
    store.state.searchAwaitingReplyHlptTicket=false;
    store.state.isAwaitingReplyHlptTicketSearching=false;
    store.state.awaitingReplyHlptTicketSortByFilter='newest';
    store.state.awaitingReplyHlptTicketEmailFilter='';
    store.state.awaitingReplyHlptTicketStatusByFilter='';
    store.state.awaitingReplyHlptTicketCategoryFilter='';
    store.state.awaitingReplyHlptTicketTierFilter='';
    store.state.awaitingReplyHlptTicketCreateMethodFilter='';
    store.state.awaitingReplyHlptTicketRefFilter='';
    store.state.awaitingReplyHlptTicketCreateStatusFilter='';
    store.state.awaitingReplyHlptTicketsPerpage=20;
    store.state.awaitingReplyHlptTickets=[];
    store.state.fetchAwaitingReplyHlptTickets=false;
    store.state.isLoadingAwaitingReplyHlptTickets=false;

    store.state.agent_role = '';

    store.state.childLibraryList = [];

    store.state.selectedChats = []

    store.state.isAddingLeadTags = false;
    store.state.tags = [];
    store.state.isLoadingArchieve = false;
    store.state.isLoadingTickets = false;
    store.state.isTicketReplying = false;
    store.state.is_away = null;
    store.state.show_alert = false;
    store.state.awayAgents = [];
    store.state.agentModeUpdated = false;
    for (const key in store.state.userPermissions) {
      if (Object.prototype.hasOwnProperty.call(store.state.userPermissions, key)) {
        let permission = store.state.userPermissions[key];
        permission = true
      }
    }
   

    store.state.agent_name = ''

    store.state.autoAssignedChatThreads = []
    if(store.state.autoAssignedChatThreadsInterval) {
      clearInterval(store.state.autoAssignedChatThreadsInterval)
      store.state.autoAssignedChatThreadsInterval = null
    }

   

    // console.log('store.state.parent_lib_id',store.state.parent_lib_id)
  }
  catch (err) {
    store.dispatch('printLog', {text:'error',log:err})
    error.value = err.message
  }
}

const useLogout = () => {
  return { error, logout }
}

export default useLogout