import { projectStorage } from '@/firebase/config'
import { getStorage,  deleteObject } from "firebase/storage";
import moment from 'moment/moment';
import { ref } from "vue"
import { useStore } from "vuex"
/* import getUser from "./getUser" */

/* const { user } = getUser() */

const useStorage = () => {
  const error = ref(null)
  const url = ref(null)
  const filePath = ref(null)
  const deleteError = ref(null);
  const store = useStore()
  const uploadImage = async (file, chat) => {
    /* filePath.value = `covers/${user.value.uid}/${file.name}` */
    /* filePath.value = `covers/12345/${moment().valueOf()/file.name}` */

    filePath.value = `covers/12345/${moment().valueOf()}_${file.name}`
    if(chat) {
      filePath.value = `chat/img/${moment().valueOf()}_${file.name}`
    }
    const storageRef = projectStorage.ref(filePath.value)


    try {
      const res = await storageRef.put(file)
      url.value = await res.ref.getDownloadURL()
      // console.log("File URL: ", url.value);
    } catch (err) {
      store.dispatch('printLog', {text:'Upload Error: ', log:err})
      error.value = err
    }
  }

  const deleteImage = async (path) => {  
    const storageRef = projectStorage.ref()
    let pictureRef = projectStorage.refFromURL(path)
    try {
      await pictureRef.delete().then(() => {
        // console.log('Image deleted successfully');
        deleteError.value = ''
      }).catch((err) => {
        store.dispatch('printLog', {text:'error while deleting image ', log:err})
        deleteError.value = 'error while deleting image'
      })
    } catch (error) {
       store.dispatch('printLog', {text:'error while deleting image ', log:error})
      deleteError.value = 'error while deleting image'
    }
  }

  return { uploadImage, deleteImage, deleteError, url, filePath, error }
}

export default useStorage;