<template>
  <ArgonSnackbar :isOpen="isError.error || isSuccess.success" :title="isError.error ? isError.msg : isSuccess.msg"
    :color="isError.error ? 'danger' : 'success'" :timeOut="10000" @close="
      isError.error = false;
    isSuccess.success = false;
    " />
  <div class="container-fluid p-0 statistics bg-lightgray" :class="{ 'colwidth-contract': !isMenuCollapsed }">
    <div class="row p-4 py-0">
      <!-- <ReportsSidebarStatic>
      </ReportsSidebarStatic> -->
      <div class="col-lg-10 col-md-9 col-12 rounded-0">
        <div
          class="p-0 pt-2 fs-4 fw-bold mb-1 border-bottom border-light d-flex align-items-center justify-content-between top-header-ratingpb">
          <h5 class="p-1 pb-2 pt-2"><i
              class="fa-regular fa-table-layout text-sm pr-3 cursor-pointer mx-3 "></i>Conversation Rating</h5>
          <!-- @click="toggleMenuCollapse" -->
          <div class="d-flex d-none">
            <button class="btn btn-light btn-sm me-3 mb-1 no-shadow no-border">
              <span> <i class="fa-regular fa-heart fa-2x"></i></span>
            </button>
          </div>
        </div>
        <div :class="isUpdating ? 'opacity-50' : ''" class="p-0">
          <div class="settings-tab m-0">
            <div class="content-scroll-h-am">
              <div class="p-4 pt-0">
                <!-- SECTION BREAK -->
                <div id="rating-1-10" class="row align-items-start mb-3 mt-0">
                  <div class="col-8 col-md-8 text-sm py-2 my-3 position-relative card-drill-export m-auto">
                    <div class="card">
                      <div class="card-body min-vh-60 d-flex align-items-center justify-content-center">
                        <template v-if="!ticketReviewSubmitted">
                          <div class="text-center">
                            <div class="row mt-0">
                              <div class="col-12 mb-3">
                                <p class="p-0 pt-3 text-lg text-bold">
                                  How would you rate your support experience?
                                </p>
                              </div>
                              <div class="col-12 mb-1">
                                <div class="row d-flex justify-content-center">
                                  <div class="text-center">
                                    <div class="d-flex justify-content-center align-items-center">
                                      <!-- <span class="text-xs me-2 d-none d-lg-flex fineprint">Not at all likely</span> -->
                                      <div id="start-rating"
                                        class="start-rating-wrap d-flex align-items-center justify-content-center pb-2">
                                        <div class="rating">
                                          <div class="rating__stars"> 
                                            <input v-for="rating in ticketRatings" :key="rating.id"
                                              :id="'rating-' + rating.id" class="rating__input"
                                              :class="'rating__input-' + rating.id" type="radio" name="rating"
                                              :value="rating.id" @change="updateCSATRating"
                                              v-model="selectedTicketRating">
                                            <label ref="ratingLabels" v-for="rating in ticketRatings" :key="rating.id"
                                              :for="'rating-' + rating.id" :ref="'rating-' + rating.id"
                                              class="rating__label">
                                              <svg class="rating__star" width="32" height="32" viewBox="0 0 32 32"
                                                aria-hidden="true">
                                                <g transform="translate(16,16)">
                                                  <circle class="rating__star-ring" fill="none" stroke="#000"
                                                    stroke-width="16" r="8" transform="scale(0)" />
                                                </g>
                                                <g stroke="#000" stroke-width="2" stroke-linecap="round"
                                                  stroke-linejoin="round">
                                                  <g transform="translate(16,16) rotate(180)">
                                                    <polygon class="rating__star-stroke"
                                                      points="0,15 4.41,6.07 14.27,4.64 7.13,-2.32 8.82,-12.14 0,-7.5 -8.82,-12.14 -7.13,-2.32 -14.27,4.64 -4.41,6.07"
                                                      fill="none" />
                                                    <polygon class="rating__star-fill"
                                                      points="0,15 4.41,6.07 14.27,4.64 7.13,-2.32 8.82,-12.14 0,-7.5 -8.82,-12.14 -7.13,-2.32 -14.27,4.64 -4.41,6.07"
                                                      fill="#000" />
                                                  </g>
                                                  <g transform="translate(16,16)" stroke-dasharray="12 12"
                                                    stroke-dashoffset="12">
                                                    <polyline class="rating__star-line" transform="rotate(0)"
                                                      points="0 4,0 16" />
                                                    <polyline class="rating__star-line" transform="rotate(72)"
                                                      points="0 4,0 16" />
                                                    <polyline class="rating__star-line" transform="rotate(144)"
                                                      points="0 4,0 16" />
                                                    <polyline class="rating__star-line" transform="rotate(216)"
                                                      points="0 4,0 16" />
                                                    <polyline class="rating__star-line" transform="rotate(288)"
                                                      points="0 4,0 16" />
                                                  </g>
                                                </g>
                                              </svg>
                                            </label>
                                            <p v-for="rating in ticketRatings" :key="rating.id"
                                              class="rating__display text-lg" :data-rating="rating.id" hidden>
                                              {{ rating.name }}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <!-- <span class="text-xs ms-2 d-none d-lg-flex fineprint">Extremely likely</span> -->
                                    </div>
                                    <!-- <div class="row text-left d-flex d-lg-none">
                                      <div class="d-flex justify-content-between align-items-center">
                                        <span class="text-xs me-2 fineprint">Not at all likely</span>
                                        <span class="text-xs ms-2 fineprint">Extremely likely</span>
                                      </div>
                                    </div> -->
                                  </div>
                                </div>
                              </div>
                              <div class="col-12 mb-1 pt-3 m-auto" style="max-width: 380px">
                                <div v-if="selectedTicketRating >= 1 && selectedTicketRating <= 3">
                                  <p class="text-sm primary-color">What specific changes would have improved your
                                    experience?</p>
                                </div>
                                <div v-if="selectedTicketRating >= 4 && selectedTicketRating <= 5">
                                  <p class="text-sm primary-color">What did you find most helpful about your experience,
                                    and what
                                    could we have done
                                    better?</p>
                                </div>
                              </div>

                              <div class="col-12 mb-1">
                                <div class="form-floating m-auto" style="max-width: 400px;">
                                  <textarea class="form-control min-h-150" id="userFeedback" name="feedback" v-model="reviewText"
                                    placeholder="Enter text here..." required></textarea>
                                  <label class="text-normal text-sm p-3" for="userFeedback">Your comments:</label>
                                </div>
                              </div>
                            </div>
                            <div class="p-4 d-flex align-items-center justify-content-center">
                              <button class="btn btn-primary p-4 py-2 mx-2 mb-1" @click="submitTicketsConvRating"
                                :disabled="selectedTicketRating == null">Submit</button>
                            </div>
                          </div>
                        </template>
                        <template v-else>
                          <div class="text-center d-flex align-items-center" style="min-height: 350px">
                            <div class="row mt-0">
                              <div class="col-12">
                                <!-- <svg v-if="this.selectedTicketRating > 3" class=" mb-3"
                                  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
                                  style="width: 50px; height: 50px;">
                                  <path fill="#c0c0c0"
                                    d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm177.6 62.1C192.8 334.5 218.8 352 256 352s63.2-17.5 78.4-33.9c9-9.7 24.2-10.4 33.9-1.4s10.4 24.2 1.4 33.9c-22 23.8-60 49.4-113.6 49.4s-91.7-25.5-113.6-49.4c-9-9.7-8.4-24.9 1.4-33.9s24.9-8.4 33.9 1.4zM144.4 208a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm192-32a32 32 0 1 1 0 64 32 32 0 1 1 0-64z" />
                                </svg>
                                <svg class="mb-3" v-if="this.selectedTicketRating == 3"
                                  style="width: 50px; height: 50px;" xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 512 512">
                                  <path
                                    d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM256 0a256 256 0 1 0 0 512A256 256 0 1 0 256 0zM176.4 240a32 32 0 1 0 0-64 32 32 0 1 0 0 64zm192-32a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zM184 328c-13.3 0-24 10.7-24 24s10.7 24 24 24l144 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-144 0z"
                                    fill="#c0c0c0" />
                                </svg>
                                <svg v-if="this.selectedTicketRating <= 2" class="mb-3"
                                  style="width: 50px; height: 50px;" xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 512 512">
                                  <path
                                    d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zM334.4 385.9C319.2 369.5 293.2 352 256 352s-63.2 17.5-78.4 33.9c-9 9.7-24.2 10.4-33.9 1.4s-10.4-24.2-1.4-33.9c22-23.8 60-49.4 113.6-49.4s91.7 25.5 113.6 49.4c9 9.7 8.4 24.9-1.4 33.9s-24.9 8.4-33.9-1.4zM144.4 208a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm192-32a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"
                                    fill="#c0c0c0" />
                                </svg> -->

                                <p class="p-0 text-lg text-bold mb-1">
                                  Thank You For Your Review!
                                </p>
                                <!-- <p class="p-0 mb-0">We truly appreciate your feedback and the time you took to rate us.
                                </p> -->

                              </div>

                            </div>
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ReportsSidebarStatic from '@/views/admin/Reports-Static/ReportsSidebarStatic.vue';
import ArgonSnackbar from '@/components/ArgonSnackbar.vue';
import { getBaseUrls } from "@/utils/getBaseUrls";


const { baseURL,  inboxBaseURL,  integrationBaseURL, teamsBaseURL, aiBaseURL, logEventsBaseURL,  widgetBaseURL, webSocketBaseURL, automationsBaseURL } = getBaseUrls();

export default {
  name: 'ReviewForm',
  components: {
    ReportsSidebarStatic,
    ArgonSnackbar,
  },
  data() {
    return {
      webSocket:'',
      selectedTicketRating: null, 
      ticketReviewSubmitted: false, 
      reviewText:'',
      isUpdating: false, 
      isMenuCollapsed: false,
      isUpdating: false,
      isError: {
        error: false,
        msg: '',
      },
      isSuccess: {
        success: false,
        msg: '',
      },
      isWebConnection:false,
      ticketRatings: [
        { id: 1, name: "Very Dissatisfied" },
        { id: 2, name: "Dissatisfied" },
        { id: 3, name: "Neutral" },
        { id: 4, name: "Satisfied" },
        { id: 5, name: "Very Satisfied" },
      ],
      rating: { id: null, name: null },
      delay: 0,
    };
  },
  created() { },
  methods: {
    submitReview() {
      let key = this.$route?.query?.key?.split('-')
      if(key) {
        let payload = {
          action:"rateMessage",
          support_lib_id:key[2].toString(),
          conversation_ref:key[0].toString(),
          message_id:key[1].toString(),
          message_review:this.reviewText,
          message_rating:+this.selectedTicketRating,
          sent_via:"portal",
          sender_ref:""
        }
        this.$store.dispatch('printLog', {text:'rateMessage', log:payload})
        this.webSocket.send(JSON.stringify(payload))
      }
    }, 
    submitTicketsConvRating() {
      if (this.selectedTicketRating) {
        if(this.isWebConnection) {
          this.submitReview()
        }

        this.$store.state.conv_ticket_rating = this.selectedTicketRating;
        // console.log(`Ready to Submit Rating: ${this.$store.state.conv_ticket_rating}`);

        this.$swal({
          title: 'Thank You!',
          html: 'We truly appreciate your feedback',
          icon: 'success',
          showCancelButton: false,
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn bg-gradient-success',
          },
          buttonsStyling: false,
        }).then(() => {
          // this.selectedTicketRating = 0;
          this.ticketReviewSubmitted = true;
          this.selectedTicketRating = 0
          this.$store.state.conv_ticket_rating = this.selectedTicketRating;
          this.reviewText = ''
          // console.log(`Rating reset to: ${this.selectedTicketRating}`);
        });
      } 
      // To be implemented: Send API request for submitting the rating
    }, 
    updateCSATRating(e) {
      const ratingLabels = this.$refs.ratingLabels;
      if (ratingLabels && Array.isArray(ratingLabels)) {
        ratingLabels.forEach((label) => {
          label.className = "rating__label";
        });
      }
      const ratingObject = this.ticketRatings.find((r) => r.id === +e.target.value);
      const prevRatingID = this.rating?.id || 0;
      this.delay = 0;
      this.rating = ratingObject;
      this.ticketRatings.forEach((rating) => {
        const { id, name } = rating;
        const ratingLabel = this.$refs[`rating-${id}`];  
        if (ratingLabel) {
          if (id > prevRatingID + 1 && id <= this.rating?.id) {
            ++this.delay;
            ratingLabel.classList.add(`rating__label--delay${this.delay}`);
          }
        }
        const ratingTextEls = this.$refs[`rating-text-${id}`];
        if (ratingTextEls) {
          if (Array.isArray(ratingTextEls)) {
            ratingTextEls.forEach((ratingTextEl) => {
              if (e.target.id === `rating-${id}`) {
                ratingTextEl.removeAttribute("hidden");
              } else {
                ratingTextEl.setAttribute("hidden", true);
              }
            });
          } else {
            if (e.target.id === `rating-${id}`) {
              ratingTextEls.removeAttribute("hidden");
            } else {
              ratingTextEls.setAttribute("hidden", true);
            }
          }
        }
        if (e.target.id === `rating-${id}`) {
          // console.log(`Clicked Rating: ${id}, ${name}`);
        }
      });
    },


    toggleMenuCollapse() {
      this.isMenuCollapsed = !this.isMenuCollapsed;
    }, 
  },
  mounted() {
    this.webSocket = new WebSocket(webSocketBaseURL)
    const rating = +this.$route?.query?.rating;
    if (rating && !isNaN(rating) && rating >= 1 && rating <= 5) {
      this.selectedTicketRating = parseInt(rating, 6);
      console.log('this.selectedTicketRating ', this.selectedTicketRating )
    }
    this.webSocket.onopen = e => {
      this.isWebConnection=true
      this.submitReview()
    };

    
    this.webSocket.onerror = error => {
      this.$store.dispatch('printLog', {text:'WebSocket error:', log:error});
      // You can add code here to handle reconnection or other logic.
    };
    this.webSocket.onclose = event => {
       this.$store.dispatch('printLog', {text:'connection closed', log:event.reason})
    };
    
  },
  beforeUnmount() {
    if(this.isWebConnection) {
      this.webSocket.close(1000, 'unload')
    }
  },
  watch: {
    selectedTicketRating(newValue) {
      // console.log("Selected Rating Updated: ", newValue);
    },
  },

};
</script>
<style scoped>
:root {
  --mi-chat-primary-color: rgb(4, 0, 255, 1);
}

/* start rating css */
:root {
  --start_rating_bg: #e3e4e8;
  --start_rating_fg: #17181c;
  --start_rating_primary: #255ff4;
  --start_rating_yellow: #f4a825;
  --start_rating_yellow_darkborder: #fca40c;
  --start_rating_yellow-t: rgba(244, 168, 37, 0);
  --start_rating_bezier: cubic-bezier(0.42, 0, 0.58, 1);
  --start_rating_trans-dur: 0.3s;
  --start_rating_fs: 1.35rem;
}

#start-rating.start-rating-wrap .rating {
  margin: auto;
}

#start-rating.start-rating-wrap .rating__display {
  font-size: 0.95rem !important;
  font-weight: 400;
  min-height: 1.25em;
  position: absolute;
  top: 90%;
  width: 100%;
  text-align: center;
  color: #666;
}

#start-rating.start-rating-wrap .rating__stars {
  display: flex;
  padding-bottom: 0.375em;
  position: relative;
}

#start-rating.start-rating-wrap .rating__star {
  display: block;
  overflow: visible;
  pointer-events: none;
  width: 2em;
  height: 2em;
}

#start-rating.start-rating-wrap .rating__star-ring,
.rating__star-fill,
.rating__star-line,
.rating__star-stroke {
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

#start-rating.start-rating-wrap .rating__star-ring,
.rating__star-fill,
.rating__star-line {
  stroke: var(--start_rating_yellow);
}

#start-rating.start-rating-wrap .rating__star-fill {
  fill: var(--start_rating_yellow);
  transform: scale(0);
  transition: fill var(--start_rating_trans-dur) var(--start_rating_bezier), transform var(--start_rating_trans-dur) var(--start_rating_bezier);
}

#start-rating.start-rating-wrap .rating__star-line {
  stroke-dasharray: 12 13;
  stroke-dashoffset: -13;
}

#start-rating.start-rating-wrap .rating__star-stroke {
  stroke: #c7cad1;
  transition: stroke var(--start_rating_trans-dur);
}

#start-rating.start-rating-wrap .rating__label {
  cursor: pointer;
  padding: 0.3em;
  margin: 0;
  font-size: 1.65rem;
}

#start-rating.start-rating-wrap .rating__label--delay1 .rating__star-ring,
#start-rating.start-rating-wrap .rating__label--delay1 .rating__star-fill,
#start-rating.start-rating-wrap .rating__label--delay1 .rating__star-line,
#start-rating.start-rating-wrap .rating__label--delay1 .rating__star-stroke {
  animation-delay: 0.05s;
}

#start-rating.start-rating-wrap .rating__label--delay2 .rating__star-ring,
#start-rating.start-rating-wrap .rating__label--delay2 .rating__star-fill,
#start-rating.start-rating-wrap .rating__label--delay2 .rating__star-line,
#start-rating.start-rating-wrap .rating__label--delay2 .rating__star-stroke {
  animation-delay: 0.1s;
}

#start-rating.start-rating-wrap .rating__label--delay3 .rating__star-ring,
#start-rating.start-rating-wrap .rating__label--delay3 .rating__star-fill,
#start-rating.start-rating-wrap .rating__label--delay3 .rating__star-line,
#start-rating.start-rating-wrap .rating__label--delay3 .rating__star-stroke {
  animation-delay: 0.15s;
}

#start-rating.start-rating-wrap .rating__label--delay4 .rating__star-ring,
#start-rating.start-rating-wrap .rating__label--delay4 .rating__star-fill,
#start-rating.start-rating-wrap .rating__label--delay4 .rating__star-line,
#start-rating.start-rating-wrap .rating__label--delay4 .rating__star-stroke {
  animation-delay: 0.2s;
}

#start-rating.start-rating-wrap .rating__input {
  position: absolute;
  -webkit-appearance: none;
  appearance: none;
}

#start-rating.start-rating-wrap .rating__input:hover~[data-rating]:not([hidden]) {
  display: none;
}

#start-rating.start-rating-wrap .rating__input-1:hover~[data-rating="1"][hidden],
#start-rating.start-rating-wrap .rating__input-2:hover~[data-rating="2"][hidden],
#start-rating.start-rating-wrap .rating__input-3:hover~[data-rating="3"][hidden],
#start-rating.start-rating-wrap .rating__input-4:hover~[data-rating="4"][hidden],
#start-rating.start-rating-wrap .rating__input-5:hover~[data-rating="5"][hidden],
#start-rating.start-rating-wrap .rating__input:checked:hover~[data-rating]:not([hidden]) {
  display: block;
}

#start-rating.start-rating-wrap .rating__input-1:hover~.rating__label:first-of-type .rating__star-stroke,
#start-rating.start-rating-wrap .rating__input-2:hover~.rating__label:nth-of-type(-n + 2) .rating__star-stroke,
#start-rating.start-rating-wrap .rating__input-3:hover~.rating__label:nth-of-type(-n + 3) .rating__star-stroke,
#start-rating.start-rating-wrap .rating__input-4:hover~.rating__label:nth-of-type(-n + 4) .rating__star-stroke,
#start-rating.start-rating-wrap .rating__input-5:hover~.rating__label:nth-of-type(-n + 5) .rating__star-stroke {
  stroke: var(--start_rating_yellow);
  transform: scale(1);
}

#start-rating.start-rating-wrap .rating__input-1:checked~.rating__label:first-of-type .rating__star-ring,
#start-rating.start-rating-wrap .rating__input-2:checked~.rating__label:nth-of-type(-n + 2) .rating__star-ring,
#start-rating.start-rating-wrap .rating__input-3:checked~.rating__label:nth-of-type(-n + 3) .rating__star-ring,
#start-rating.start-rating-wrap .rating__input-4:checked~.rating__label:nth-of-type(-n + 4) .rating__star-ring,
#start-rating.start-rating-wrap .rating__input-5:checked~.rating__label:nth-of-type(-n + 5) .rating__star-ring {
  animation-name: starRing;
}

#start-rating.start-rating-wrap .rating__input-1:checked~.rating__label:first-of-type .rating__star-stroke,
#start-rating.start-rating-wrap .rating__input-2:checked~.rating__label:nth-of-type(-n + 2) .rating__star-stroke,
#start-rating.start-rating-wrap .rating__input-3:checked~.rating__label:nth-of-type(-n + 3) .rating__star-stroke,
#start-rating.start-rating-wrap .rating__input-4:checked~.rating__label:nth-of-type(-n + 4) .rating__star-stroke,
#start-rating.start-rating-wrap .rating__input-5:checked~.rating__label:nth-of-type(-n + 5) .rating__star-stroke {
  animation-name: starStroke;
}

#start-rating.start-rating-wrap .rating__input-1:checked~.rating__label:first-of-type .rating__star-line,
#start-rating.start-rating-wrap .rating__input-2:checked~.rating__label:nth-of-type(-n + 2) .rating__star-line,
#start-rating.start-rating-wrap .rating__input-3:checked~.rating__label:nth-of-type(-n + 3) .rating__star-line,
#start-rating.start-rating-wrap .rating__input-4:checked~.rating__label:nth-of-type(-n + 4) .rating__star-line,
#start-rating.start-rating-wrap .rating__input-5:checked~.rating__label:nth-of-type(-n + 5) .rating__star-line {
  animation-name: starLine;
}

#start-rating.start-rating-wrap .rating__input-1:checked~.rating__label:first-of-type .rating__star-fill,
#start-rating.start-rating-wrap .rating__input-2:checked~.rating__label:nth-of-type(-n + 2) .rating__star-fill,
#start-rating.start-rating-wrap .rating__input-3:checked~.rating__label:nth-of-type(-n + 3) .rating__star-fill,
#start-rating.start-rating-wrap .rating__input-4:checked~.rating__label:nth-of-type(-n + 4) .rating__star-fill,
#start-rating.start-rating-wrap .rating__input-5:checked~.rating__label:nth-of-type(-n + 5) .rating__star-fill {
  animation-name: starFill;
}

#start-rating.start-rating-wrap .rating__input-1:not(:checked):hover~.rating__label:first-of-type .rating__star-fill,
#start-rating.start-rating-wrap .rating__input-2:not(:checked):hover~.rating__label:nth-of-type(2) .rating__star-fill,
#start-rating.start-rating-wrap .rating__input-3:not(:checked):hover~.rating__label:nth-of-type(3) .rating__star-fill,
#start-rating.start-rating-wrap .rating__input-4:not(:checked):hover~.rating__label:nth-of-type(4) .rating__star-fill,
#start-rating.start-rating-wrap .rating__input-5:not(:checked):hover~.rating__label:nth-of-type(5) .rating__star-fill {
  fill: var(--start_rating_yellow-t);
}

#start-rating.start-rating-wrap .rating__sr {
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
}


@media (prefers-color-scheme: dark) {
  :root {
    --start_rating_bg: #17181c;
    --start_rating_fg: #e3e4e8;
  }

  #start-rating.start-rating-wrap .rating {
    margin: auto;
  }

  #start-rating.start-rating-wrap .rating__star-stroke {
    stroke: #454954;
  }
}

@keyframes starRing {

  from,
  20% {
    animation-timing-function: ease-in;
    opacity: 1;
    r: 8px;
    stroke-width: 16px;
    transform: scale(0);
  }

  35% {
    animation-timing-function: ease-out;
    opacity: 0.5;
    r: 8px;
    stroke-width: 16px;
    transform: scale(1);
  }

  50%,
  to {
    opacity: 0;
    r: 16px;
    stroke-width: 0;
    transform: scale(1);
  }
}

@keyframes starFill {

  from,
  40% {
    animation-timing-function: ease-out;
    transform: scale(0);
  }

  60% {
    animation-timing-function: ease-in-out;
    transform: scale(1.2);
  }

  80% {
    transform: scale(0.9);
  }

  to {
    transform: scale(1);
  }
}

@keyframes starStroke {
  from {
    transform: scale(1);
  }

  20%,
  to {
    transform: scale(0);
  }
}

@keyframes starLine {

  from,
  40% {
    animation-timing-function: ease-out;
    stroke-dasharray: 1 23;
    stroke-dashoffset: 1;
  }

  60%,
  to {
    stroke-dasharray: 12 13;
    stroke-dashoffset: -13;
  }
}


#start-rating {
  caret-color: transparent !important;
}

#start-rating.start-rating-wrap .rating__label[for="rating-1"]:hover~.rating__display[data-rating="1"],
#start-rating.start-rating-wrap .rating__label[for="rating-2"]:hover~.rating__display[data-rating="2"],
#start-rating.start-rating-wrap .rating__label[for="rating-3"]:hover~.rating__display[data-rating="3"],
#start-rating.start-rating-wrap .rating__label[for="rating-4"]:hover~.rating__display[data-rating="4"],
#start-rating.start-rating-wrap .rating__label[for="rating-5"]:hover~.rating__display[data-rating="5"] {
  display: block !important;
}



/* end of star rating */
#rating-1-10 textarea.min-h-150 {
  min-height: 130px;
}

#rating-1-10 .form-floating >.form-control {
  padding: 1.5rem 1.2rem !important;
  padding-top: 2.5rem !important;
}
#rating-1-10 .form-floating>.form-control label {
  padding-top: 1.5rem !important;
}

#rating-1-10 .form-check-input {
  display: none;
}

#rating-1-10 .form-check-label {
  width: 42px;
  height: 42px;
  background-color: #e5e5e5;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

#rating-1-10 .form-check-input:checked+.form-check-label.best-rate,
#rating-1-10 .form-check-input:checked+.form-check-label {
  background-color: #4bba87 !important;
  color: white !important;
  transform: scale(1.1);
  caret-color: transparent !important;
}

#rating-1-10 .form-check-input:checked+.form-check-label.good-rate {
  background-color: #99d1a5 !important;
}
#rating-1-10 .form-check-input:checked+.form-check-label.normal-rate {
  background-color: #e7c503 !important;
}
#rating-1-10 .form-check-input:checked+.form-check-label.bad-rate {
  background-color: #dd2d01 !important;
}

#rating-1-10 .form-check-label:hover {
  background-color: #d1d1d1 !important;
  /* transform: scale(1.1); */
}

#rating-1-10 .small-text {
  font-size: 0.8rem;
  font-weight: bold;
  color: #333;
}

#rating-1-10 .form-check {
  padding-left: unset !important;
}
#rating-1-10 .form-check {
  margin-right: 5px;

}

@media (min-width: 992px) {
  #rating-1-10 .form-check {
    outline: none;
  }
}

@media (max-width: 991px) {
  #rating-1-10 .form-check {
    outline: none;
  }
}
#rating-1-10 .card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow-wrap: break-word;
}
#rating-1-10 .card:not(.border-0) {
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  max-width: 800px;
  margin: auto;
  margin-top: 20px;
  box-shadow: 0px 0px 20px 0px #e9e9e9;
}
#rating-1-10 .card:not(.border-0):hover {
  border: 1px solid #e2e2e2;
  box-shadow: 0px 10px 20px 0px #e5e5e5;
}
#rating-1-10 button.btn.btn-primary{
  background-color: var(--mi-chat-primary-color) !important;
  color: #fff !important;
}
.primary-color {
  color: var(--mi-chat-primary-color) !important;
}

.top-header-ratingpb {
  background-color: #fff;
}
.bg-lightgray {
  background: #f1f1f1;
  height: 100vh;
}
.fineprint {
  color: #c0c0c0 !important;
}
.form-floating>label {
  width: 450px;
  height: 50px;
  text-align: left;
  opacity: 1 !important;
  background-color: #fff;
  z-index: 9;
  top: 5px;
}
.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-select~label {
  top: 7px;
  border-top: 1px solid #f2f2f2;
}
</style>

