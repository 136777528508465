<template>
    <div class="main-container h-100">
        <div class="nav-home">
            <div class="nav-icon-container" v-if="view !== 'categories'" @click="view == 'single-article' ? view = 'articles' : view == 'articles' ? view = 'categories' : ''"><i class="fa fa-chevron-left mb-3 nav-icon text-lg"
                    aria-hidden="true"></i></div>
            <div class="nav-item-selected"> BROWSE SOP </div>
            <!-- <div class="customEmailSupportLink nav-item nav-item-home"> CONTACT US </div> -->

        </div>
        <div class="home-main pt-3">
            <div class="row">
                <!-- <div style="display: none;">
            <div>
                <div class="home-select-section">
                    <h2>
                        <div>What do you need help with?</div>
                    </h2><span>
                        <div>Choose an option below: </div>
                    </span><button>
                        <p> I need help with a specific topic</p>
                    </button><button>
                        <p> I'm trying to do something and not sure how your app can help me do it</p>
                    </button><button>
                        <p>I have a billing question</p>
                    </button><button>
                        <p>I'm having login issues</p>
                    </button>
                </div>
            </div>
        </div>-->
                <!-- <div>
                    <div class="ms-md-auto">
                        <div class="input-group input-group-home"><input type="text" class="form-control form-control-home"
                                placeholder="Search our SOPs">
                            <div class="search-button"><i class="fa fa-search" aria-hidden="true" style="color: black;"></i>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div style="display: none;">
                    <div class="search-warning">
                        <div class="warning-close-btn"><i class="fa fa-times" aria-hidden="true"></i></div>
                        <div>Using only one keyword will lead to irrelevant results. Add more keywords to get more accurate
                            results. </div>
                    </div>
                </div>
                <div v-if="view == 'categories'">
                    <!-- <h4 class="category-title help-title">How can we help?</h4> -->
                    <hr color="#E8E8E8">
                    <template v-if="isLoading">
                        <Loading />
                    </template>
                    <template v-else>
                        <template v-for="category, index in sopCategories" :key="index">
                            <div class="section-title text-sm" @click="filterCategory = category.category_id, selectedCategory = category, getSopArticles(), view = 'articles'">
                                <i class="fa fa-chevron-right me-4" aria-hidden="true" style="color: rgb(213, 212, 212);"></i>
                                {{ category.category_title }}
                            </div>
                            <hr>
                        </template>
                    </template>
                </div>

                <div v-if="view == 'articles'">
                    <h3 class="text-white1 category-title h5 py-2">{{ selectedCategory?.category_title }}</h3>
                    <template v-if="isArticleLoading">
                        <Loading />
                    </template>
                    <template v-else>
                        <template v-for="articles in  sopArticles">
                        <div class="section-title fw-normal" @click="selectedArticle = articles, view = 'single-article'"><i class="fa fa-file-text-o me-3" aria-hidden="true"
                                style="font-size: 1.4em; line-height: 1.35em; color: rgb(213, 212, 212);"></i><span class="text-sm"> {{articles.sop_title}}</span></div>
                        <hr>
                        </template>
                    </template>
                </div>

                <div v-if="view == 'single-article'" class="single-article h-scroll">
                    <div class="d-flex">
                        <h4 class="text-white1 category-title mb-2 cursor-pointer fw-normal selected-category-title fs-5 fw-bold ">{{ selectedCategory.category_title }}</h4>
                        
                    </div>
                    <h5 class="article-title mt-2 fs-5">{{selectedArticle.sop_title}}</h5>
                    <div class="article-content p-3 pl-0" v-html="selectedArticle.sop_content">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Api from '../Api';
import Loading from '@/components/custom/Loading.vue';

export default {
    name: 'PreviewSop',
    components:{
        Loading
    },
    data() {
        return {
            isLoading: false,
            isArticleLoading:false,
            sopCategories: [],
            sopArticles:[],
            selectedCategory:{},
            selectedArticle:{},
            support_lib_id: localStorage.getItem('support_Lib_id'),
            view: 'categories',
            filterCategory:'',
        }
    },
    methods: {
        async initCategories() {
            this.isLoading = true;
            await Api.getSopCategories(this.support_lib_id)
                .then(res => {
                    if (res?.data?.sop_categories) {
                        this.sopCategories = res?.data?.sop_categories;
                    }
                })
                .catch(error => {
                    this.$store.dispatch('printLog', {text:'error fetching error :- ', log:error});
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        async getSopArticles() {
            this.isArticleLoading = true
            let params = {
                support_lib_id: this.support_lib_id
            }
            if (this.filterCategory) {
                params.category_id = this.filterCategory
            }
            await Api.getSopArticlesbackend(params).then(res => {
                if (res?.data?.sop_articles) {
                    this.sopArticles = res?.data?.sop_articles;
                }
            })
            .catch(error => {
                this.$store.dispatch('printLog', {text:'error fetching error :- ', log:error});
            })
            .finally(() => {
                this.isArticleLoading = false
            });
        },
    },
    mounted() {
        this.initCategories()
    }
}
</script>