<template>
    
    <div class="container-fluid p-0 statistics bg-lightgray" :class="{ 'colwidth-contract': !isMenuCollapsed }">
      <div class="row p-4 py-0">
        <!-- <ReportsSidebarStatic>
        </ReportsSidebarStatic> -->
        <div class="col-lg-10 col-md-9 col-12 rounded-0">
          <div
            class="p-0 pt-2 fs-4 fw-bold mb-1 border-bottom border-light d-flex align-items-center justify-content-between top-header-ratingpb">
            <h5 class="p-1 pb-2 pt-2"><i
                class="fa-regular fa-table-layout text-sm pr-3 cursor-pointer mx-3 "></i>Generate Agency Data With Modal Integration </h5>
            <!-- @click="toggleMenuCollapse" -->
            <div class="d-flex d-none">
              <button class="btn btn-light btn-sm me-3 mb-1 no-shadow no-border">
                <span> <i class="fa-regular fa-heart fa-2x"></i></span>
              </button>
            </div>
          </div>
          <div :class="isUpdating ? 'opacity-50' : ''" class="p-0">
            <div class="settings-tab m-0">
              <div class="content-scroll-h-am">
                <div class="p-4 pt-0">
                  <!-- SECTION BREAK -->
                  <div id="rating-1-10" class="row align-items-start mb-3 mt-0">
                    <div class="col-8 col-md-8 text-sm py-2 my-3 position-relative card-drill-export m-auto">
                      <div class="card">
                        <div class="card-body min-h-400px d-flex align-items-center justify-content-center">
                            <button class="btn btn-primary p-4 py-2 mx-2 mb-1" @click="getData()">
                                <span v-if="isDataLoading">Generating... <i class="fa fa-circle-o-notch fa-spin"></i></span>   
                                <span v-else>  Generate Csv</span>
                            </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import ReportsSidebarStatic from '@/views/admin/Reports-Static/ReportsSidebarStatic.vue';
  import ArgonSnackbar from '@/components/ArgonSnackbar.vue';
  import { getBaseUrls } from "@/utils/getBaseUrls";
  import { projectFirestore } from '@/firebase/config';
  
  
  const { baseURL,  inboxBaseURL,  integrationBaseURL, teamsBaseURL, aiBaseURL, logEventsBaseURL,  widgetBaseURL, webSocketBaseURL, automationsBaseURL } = getBaseUrls();
  
  export default {
    name: 'GetModalIntegrationsAgencies',
    components: {
      ReportsSidebarStatic,
      ArgonSnackbar,
    },
    data() {
      return {
        webSocket:'',
        selectedTicketRating: null, 
        ticketReviewSubmitted: false, 
        reviewText:'',
        isUpdating: false, 
        isMenuCollapsed: false,
        isDataLoading:false,
        isUpdating: false,
        isError: {
          error: false,
          msg: '',
        },
        isSuccess: {
          success: false,
          msg: '',
        },
        isWebConnection:false,
        ticketRatings: [
          { id: 1, name: "Very Dissatisfied" },
          { id: 2, name: "Dissatisfied" },
          { id: 3, name: "Neutral" },
          { id: 4, name: "Satisfied" },
          { id: 5, name: "Very Satisfied" },
        ],
        rating: { id: null, name: null },
        delay: 0,
      };
    },
    created() { },
    methods: {
        async getData() {
            let modalChatData = []
            this.isDataLoading = true
            await projectFirestore.collection('SupportLibraries').get().then((doc) => {
                if (!doc.empty) {
                let data = doc.docs.map((el) => ({supportLibId:el.id, ...el.data()})).filter((elem) => elem?.modalChatIntegration && elem?.modalChatIntegration?.enable == true)
                // console.log('modalChatIntegration ', data)
                let csvHeaders = ['supportLibId', 'library_email', 'library_domain', 'custom_subDomain', 'library_users', 'library_only', 'library_refkey', 'parent_lib_id']
                // Convert array to CSV format
                let csvContent = csvHeaders.join(',') + '\n' + data
                    .map((row) => csvHeaders.map((header) => `"${row[header] || ''}"`).join(','))
                    .join('\n');

                // Create a Blob and generate a download link
                let blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
                let url = URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = 'Agencies_with_modal_chat.csv';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                URL.revokeObjectURL(url); // Clean up
                }
            }).catch(() => {

            }).finally(() => {
                this.isDataLoading = false
            })
        }
    },
  
  };
  </script>
  <style scoped>
  :root {
    --mi-chat-primary-color: rgb(4, 0, 255, 1);
  }
  
  /* start rating css */
  :root {
    --start_rating_bg: #e3e4e8;
    --start_rating_fg: #17181c;
    --start_rating_primary: #255ff4;
    --start_rating_yellow: #f4a825;
    --start_rating_yellow_darkborder: #fca40c;
    --start_rating_yellow-t: rgba(244, 168, 37, 0);
    --start_rating_bezier: cubic-bezier(0.42, 0, 0.58, 1);
    --start_rating_trans-dur: 0.3s;
    --start_rating_fs: 1.35rem;
  }
  
  #start-rating.start-rating-wrap .rating {
    margin: auto;
  }
  
  #start-rating.start-rating-wrap .rating__display {
    font-size: 0.95rem !important;
    font-weight: 400;
    min-height: 1.25em;
    position: absolute;
    top: 90%;
    width: 100%;
    text-align: center;
    color: #666;
  }
  
  #start-rating.start-rating-wrap .rating__stars {
    display: flex;
    padding-bottom: 0.375em;
    position: relative;
  }
  
  #start-rating.start-rating-wrap .rating__star {
    display: block;
    overflow: visible;
    pointer-events: none;
    width: 2em;
    height: 2em;
  }
  
  #start-rating.start-rating-wrap .rating__star-ring,
  .rating__star-fill,
  .rating__star-line,
  .rating__star-stroke {
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
  }
  
  #start-rating.start-rating-wrap .rating__star-ring,
  .rating__star-fill,
  .rating__star-line {
    stroke: var(--start_rating_yellow);
  }
  
  #start-rating.start-rating-wrap .rating__star-fill {
    fill: var(--start_rating_yellow);
    transform: scale(0);
    transition: fill var(--start_rating_trans-dur) var(--start_rating_bezier), transform var(--start_rating_trans-dur) var(--start_rating_bezier);
  }
  
  #start-rating.start-rating-wrap .rating__star-line {
    stroke-dasharray: 12 13;
    stroke-dashoffset: -13;
  }
  
  #start-rating.start-rating-wrap .rating__star-stroke {
    stroke: #c7cad1;
    transition: stroke var(--start_rating_trans-dur);
  }
  
  #start-rating.start-rating-wrap .rating__label {
    cursor: pointer;
    padding: 0.3em;
    margin: 0;
    font-size: 1.65rem;
  }
  
  #start-rating.start-rating-wrap .rating__label--delay1 .rating__star-ring,
  #start-rating.start-rating-wrap .rating__label--delay1 .rating__star-fill,
  #start-rating.start-rating-wrap .rating__label--delay1 .rating__star-line,
  #start-rating.start-rating-wrap .rating__label--delay1 .rating__star-stroke {
    animation-delay: 0.05s;
  }
  
  #start-rating.start-rating-wrap .rating__label--delay2 .rating__star-ring,
  #start-rating.start-rating-wrap .rating__label--delay2 .rating__star-fill,
  #start-rating.start-rating-wrap .rating__label--delay2 .rating__star-line,
  #start-rating.start-rating-wrap .rating__label--delay2 .rating__star-stroke {
    animation-delay: 0.1s;
  }
  
  #start-rating.start-rating-wrap .rating__label--delay3 .rating__star-ring,
  #start-rating.start-rating-wrap .rating__label--delay3 .rating__star-fill,
  #start-rating.start-rating-wrap .rating__label--delay3 .rating__star-line,
  #start-rating.start-rating-wrap .rating__label--delay3 .rating__star-stroke {
    animation-delay: 0.15s;
  }
  
  #start-rating.start-rating-wrap .rating__label--delay4 .rating__star-ring,
  #start-rating.start-rating-wrap .rating__label--delay4 .rating__star-fill,
  #start-rating.start-rating-wrap .rating__label--delay4 .rating__star-line,
  #start-rating.start-rating-wrap .rating__label--delay4 .rating__star-stroke {
    animation-delay: 0.2s;
  }
  
  #start-rating.start-rating-wrap .rating__input {
    position: absolute;
    -webkit-appearance: none;
    appearance: none;
  }
  
  #start-rating.start-rating-wrap .rating__input:hover~[data-rating]:not([hidden]) {
    display: none;
  }
  
  #start-rating.start-rating-wrap .rating__input-1:hover~[data-rating="1"][hidden],
  #start-rating.start-rating-wrap .rating__input-2:hover~[data-rating="2"][hidden],
  #start-rating.start-rating-wrap .rating__input-3:hover~[data-rating="3"][hidden],
  #start-rating.start-rating-wrap .rating__input-4:hover~[data-rating="4"][hidden],
  #start-rating.start-rating-wrap .rating__input-5:hover~[data-rating="5"][hidden],
  #start-rating.start-rating-wrap .rating__input:checked:hover~[data-rating]:not([hidden]) {
    display: block;
  }
  
  #start-rating.start-rating-wrap .rating__input-1:hover~.rating__label:first-of-type .rating__star-stroke,
  #start-rating.start-rating-wrap .rating__input-2:hover~.rating__label:nth-of-type(-n + 2) .rating__star-stroke,
  #start-rating.start-rating-wrap .rating__input-3:hover~.rating__label:nth-of-type(-n + 3) .rating__star-stroke,
  #start-rating.start-rating-wrap .rating__input-4:hover~.rating__label:nth-of-type(-n + 4) .rating__star-stroke,
  #start-rating.start-rating-wrap .rating__input-5:hover~.rating__label:nth-of-type(-n + 5) .rating__star-stroke {
    stroke: var(--start_rating_yellow);
    transform: scale(1);
  }
  
  #start-rating.start-rating-wrap .rating__input-1:checked~.rating__label:first-of-type .rating__star-ring,
  #start-rating.start-rating-wrap .rating__input-2:checked~.rating__label:nth-of-type(-n + 2) .rating__star-ring,
  #start-rating.start-rating-wrap .rating__input-3:checked~.rating__label:nth-of-type(-n + 3) .rating__star-ring,
  #start-rating.start-rating-wrap .rating__input-4:checked~.rating__label:nth-of-type(-n + 4) .rating__star-ring,
  #start-rating.start-rating-wrap .rating__input-5:checked~.rating__label:nth-of-type(-n + 5) .rating__star-ring {
    animation-name: starRing;
  }
  
  #start-rating.start-rating-wrap .rating__input-1:checked~.rating__label:first-of-type .rating__star-stroke,
  #start-rating.start-rating-wrap .rating__input-2:checked~.rating__label:nth-of-type(-n + 2) .rating__star-stroke,
  #start-rating.start-rating-wrap .rating__input-3:checked~.rating__label:nth-of-type(-n + 3) .rating__star-stroke,
  #start-rating.start-rating-wrap .rating__input-4:checked~.rating__label:nth-of-type(-n + 4) .rating__star-stroke,
  #start-rating.start-rating-wrap .rating__input-5:checked~.rating__label:nth-of-type(-n + 5) .rating__star-stroke {
    animation-name: starStroke;
  }
  
  #start-rating.start-rating-wrap .rating__input-1:checked~.rating__label:first-of-type .rating__star-line,
  #start-rating.start-rating-wrap .rating__input-2:checked~.rating__label:nth-of-type(-n + 2) .rating__star-line,
  #start-rating.start-rating-wrap .rating__input-3:checked~.rating__label:nth-of-type(-n + 3) .rating__star-line,
  #start-rating.start-rating-wrap .rating__input-4:checked~.rating__label:nth-of-type(-n + 4) .rating__star-line,
  #start-rating.start-rating-wrap .rating__input-5:checked~.rating__label:nth-of-type(-n + 5) .rating__star-line {
    animation-name: starLine;
  }
  
  #start-rating.start-rating-wrap .rating__input-1:checked~.rating__label:first-of-type .rating__star-fill,
  #start-rating.start-rating-wrap .rating__input-2:checked~.rating__label:nth-of-type(-n + 2) .rating__star-fill,
  #start-rating.start-rating-wrap .rating__input-3:checked~.rating__label:nth-of-type(-n + 3) .rating__star-fill,
  #start-rating.start-rating-wrap .rating__input-4:checked~.rating__label:nth-of-type(-n + 4) .rating__star-fill,
  #start-rating.start-rating-wrap .rating__input-5:checked~.rating__label:nth-of-type(-n + 5) .rating__star-fill {
    animation-name: starFill;
  }
  
  #start-rating.start-rating-wrap .rating__input-1:not(:checked):hover~.rating__label:first-of-type .rating__star-fill,
  #start-rating.start-rating-wrap .rating__input-2:not(:checked):hover~.rating__label:nth-of-type(2) .rating__star-fill,
  #start-rating.start-rating-wrap .rating__input-3:not(:checked):hover~.rating__label:nth-of-type(3) .rating__star-fill,
  #start-rating.start-rating-wrap .rating__input-4:not(:checked):hover~.rating__label:nth-of-type(4) .rating__star-fill,
  #start-rating.start-rating-wrap .rating__input-5:not(:checked):hover~.rating__label:nth-of-type(5) .rating__star-fill {
    fill: var(--start_rating_yellow-t);
  }
  
  #start-rating.start-rating-wrap .rating__sr {
    clip: rect(1px, 1px, 1px, 1px);
    overflow: hidden;
    position: absolute;
    width: 1px;
    height: 1px;
  }
  
  
  @media (prefers-color-scheme: dark) {
    :root {
      --start_rating_bg: #17181c;
      --start_rating_fg: #e3e4e8;
    }
  
    #start-rating.start-rating-wrap .rating {
      margin: auto;
    }
  
    #start-rating.start-rating-wrap .rating__star-stroke {
      stroke: #454954;
    }
  }
  
  @keyframes starRing {
  
    from,
    20% {
      animation-timing-function: ease-in;
      opacity: 1;
      r: 8px;
      stroke-width: 16px;
      transform: scale(0);
    }
  
    35% {
      animation-timing-function: ease-out;
      opacity: 0.5;
      r: 8px;
      stroke-width: 16px;
      transform: scale(1);
    }
  
    50%,
    to {
      opacity: 0;
      r: 16px;
      stroke-width: 0;
      transform: scale(1);
    }
  }
  
  @keyframes starFill {
  
    from,
    40% {
      animation-timing-function: ease-out;
      transform: scale(0);
    }
  
    60% {
      animation-timing-function: ease-in-out;
      transform: scale(1.2);
    }
  
    80% {
      transform: scale(0.9);
    }
  
    to {
      transform: scale(1);
    }
  }
  
  @keyframes starStroke {
    from {
      transform: scale(1);
    }
  
    20%,
    to {
      transform: scale(0);
    }
  }
  
  @keyframes starLine {
  
    from,
    40% {
      animation-timing-function: ease-out;
      stroke-dasharray: 1 23;
      stroke-dashoffset: 1;
    }
  
    60%,
    to {
      stroke-dasharray: 12 13;
      stroke-dashoffset: -13;
    }
  }
  
  
  #start-rating {
    caret-color: transparent !important;
  }
  
  #start-rating.start-rating-wrap .rating__label[for="rating-1"]:hover~.rating__display[data-rating="1"],
  #start-rating.start-rating-wrap .rating__label[for="rating-2"]:hover~.rating__display[data-rating="2"],
  #start-rating.start-rating-wrap .rating__label[for="rating-3"]:hover~.rating__display[data-rating="3"],
  #start-rating.start-rating-wrap .rating__label[for="rating-4"]:hover~.rating__display[data-rating="4"],
  #start-rating.start-rating-wrap .rating__label[for="rating-5"]:hover~.rating__display[data-rating="5"] {
    display: block !important;
  }
  
  
  
  /* end of star rating */
  #rating-1-10 textarea.min-h-150 {
    min-height: 130px;
  }
  
  #rating-1-10 .form-floating >.form-control {
    padding: 1.5rem 1.2rem !important;
    padding-top: 2.5rem !important;
  }
  #rating-1-10 .form-floating>.form-control label {
    padding-top: 1.5rem !important;
  }
  
  #rating-1-10 .form-check-input {
    display: none;
  }
  
  #rating-1-10 .form-check-label {
    width: 42px;
    height: 42px;
    background-color: #e5e5e5;
    border-radius: 8px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  #rating-1-10 .form-check-input:checked+.form-check-label.best-rate,
  #rating-1-10 .form-check-input:checked+.form-check-label {
    background-color: #4bba87 !important;
    color: white !important;
    transform: scale(1.1);
    caret-color: transparent !important;
  }
  
  #rating-1-10 .form-check-input:checked+.form-check-label.good-rate {
    background-color: #99d1a5 !important;
  }
  #rating-1-10 .form-check-input:checked+.form-check-label.normal-rate {
    background-color: #e7c503 !important;
  }
  #rating-1-10 .form-check-input:checked+.form-check-label.bad-rate {
    background-color: #dd2d01 !important;
  }
  
  #rating-1-10 .form-check-label:hover {
    background-color: #d1d1d1 !important;
    /* transform: scale(1.1); */
  }
  
  #rating-1-10 .small-text {
    font-size: 0.8rem;
    font-weight: bold;
    color: #333;
  }
  
  #rating-1-10 .form-check {
    padding-left: unset !important;
  }
  #rating-1-10 .form-check {
    margin-right: 5px;
  
  }
  
  @media (min-width: 992px) {
    #rating-1-10 .form-check {
      outline: none;
    }
  }
  
  @media (max-width: 991px) {
    #rating-1-10 .form-check {
      outline: none;
    }
  }
  #rating-1-10 .card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    overflow-wrap: break-word;
  }
  #rating-1-10 .card:not(.border-0) {
    border: 1px solid #e9e9e9;
    border-radius: 10px;
    max-width: 800px;
    margin: auto;
    margin-top: 20px;
    box-shadow: 0px 0px 20px 0px #e9e9e9;
  }
  #rating-1-10 .card:not(.border-0):hover {
    border: 1px solid #e2e2e2;
    box-shadow: 0px 10px 20px 0px #e5e5e5;
  }
  #rating-1-10 button.btn.btn-primary{
    background-color: var(--mi-chat-primary-color) !important;
    color: #fff !important;
  }
  .primary-color {
    color: var(--mi-chat-primary-color) !important;
  }
  
  .top-header-ratingpb {
    background-color: #fff;
  }
  .bg-lightgray {
    background: #f1f1f1;
    height: 100vh;
  }
  .fineprint {
    color: #c0c0c0 !important;
  }
  .form-floating>label {
    width: 450px;
    height: 50px;
    text-align: left;
    opacity: 1 !important;
    background-color: #fff;
    z-index: 9;
    top: 5px;
  }
  .form-floating>.form-control:focus~label,
  .form-floating>.form-control:not(:placeholder-shown)~label,
  .form-floating>.form-select~label {
    top: 7px;
    border-top: 1px solid #f2f2f2;
  }
  .min-h-400px {
    min-height:400px
  }
  </style>
  
  