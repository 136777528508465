import { ref } from 'vue';
import { projectFirestore } from '../../firebase/config';
import { useStore } from 'vuex';

const fgetUserAndHlptCategoriesAndArticles = () => {
  const store = useStore()
  let hlpt_support_library_id = store.state.parent_lib_id;
  const allCategoriesAndArticles = ref({});
  const searchResults = ref([]);
  const prevSearchQuery = ref('');

  const calcOcc = (title, searchArr) => {
    let occr = 0;
    searchArr.forEach((ele) => {
      if (title.includes(ele)) {
        occr += 1
      }
    })
    return occr
  }
  const charactersToBeRemoved = ['a', 'an', 'and', 'are', 'as', 'at', 'be', 'by', 'for', 'from', 'has', 'have', 'how', 'in', 'is', 'it', 'of', 'on', 'or', 'that', 'the', 'to', 'was', 'what', 'when', 'where', 'who', 'will', 'with', 'you', 'about', 'all', 'also', 'any', 'but', 'can', 'do', 'even', 'every', 'if', 'into', 'no', 'not', 'only', 'other', 'our', 'should', 'so', 'some', 'their', 'them', 'these', 'this', 'us', 'we', 'which', 'while', 'would'];

  const searchArticles = async (library_key, searchQuery) => {
    hlpt_support_library_id = store.state.parent_lib_id;
    // console.log('hlpt_support_library_id',hlpt_support_library_id)
    let defaultDisableArticles = [];
    let disabled_default_categories = []
    let enabled_default_articles = []
    let enabled_default_categories = []
    let support_lib_ids = []
    let defaultDisabledCategories = [];
    return await projectFirestore.collection('SupportLibraries').doc(library_key).get().then(async (res) => {
      if (res.exists) {
        // console.log('res.data().disabled_default_articles;',res.data().disabled_default_articles)
        defaultDisableArticles = res?.data()?.disabled_default_articles ? res.data().disabled_default_articles : [];
        if (res.data()?.disabled_default_categories) {
          disabled_default_categories = res.data().disabled_default_categories
        }
        if (res.data()?.enabled_default_articles) {
          enabled_default_articles = res.data().enabled_default_articles
        }
        if (res.data()?.enabled_default_categories) {
          enabled_default_categories = res.data().enabled_default_categories
        }
      }
      if (!!hlpt_support_library_id) {
        support_lib_ids = [library_key, hlpt_support_library_id]
      } else {
        support_lib_ids = [library_key]
      }

      const fetchDefaultDisabledCategories = () => {
        return new Promise((resolve, reject) => {
          if (hlpt_support_library_id) {
            projectFirestore.collection('Categories').where('support_lib_id', '==', hlpt_support_library_id).where('is_enabled', '==', false).get().then((catRes) => {
              if (!catRes.empty) {
                defaultDisabledCategories = catRes.docs.map((el) => el.id)
                resolve()
              } else {
                resolve()
              }
            })
          } else {
            resolve()
          }
        })
      }
      await fetchDefaultDisabledCategories()
      searchResults.value = [];
      let searches = []
      return new Promise(async (resolve, reject) => {
        try {
          let searchStr = searchQuery.toLowerCase();
          charactersToBeRemoved.forEach((el) => {
            if (searchStr.split(' ').includes(el)) {
              searchStr = searchStr.trim().split(' ').filter((elem) => elem !== el && elem !== '').join(' ');
            }
          });
          var searchQueryData = []
          searchQueryData = searchStr.split(' ');
          const categoryQuery = projectFirestore.collection("Categories")
            .where("support_lib_id", "in", support_lib_ids)
            .where("is_enabled", "==", true);

          await categoryQuery.get().then(async (snap) => {
            let promises = [];
            snap.docs.forEach((category) => {
              if (category.data().category_title && (!defaultDisabledCategories.includes(category.id) && (category.data().is_disabled !== true || enabled_default_categories.includes(category.id)))) {
                const articleQuery = projectFirestore.collection("Articles")
                  .where("is_published", "==", true)
                  .where('support_lib_id', 'in', support_lib_ids)
                  .where('article_categories', '==', category.id);
                promises.push(articleQuery.get().then((articleSnap) => {
                  articleSnap.docs.forEach(currentDoc => {
                    var is_default_enabled = true;
                    if (!disabled_default_categories.includes(currentDoc.data().article_categories) && category.data().is_enabled == true && category.id == currentDoc.data().article_categories && !defaultDisableArticles.includes(currentDoc.id) && (currentDoc.data().is_disabled !== true || enabled_default_articles.includes(currentDoc.id))) {
                      if (currentDoc.data().article_title.split(" ").join("").toLowerCase() === searchQuery.split(" ").join("").toLowerCase()) {
                        searches.push({ ...currentDoc.data(), id: currentDoc.id, is_default_enabled: is_default_enabled, searchorder: 'title0', categoryTitle: category.data().category_title, })
                      }


                      if (currentDoc.data().article_title.toLowerCase().startsWith(searchStr.toLowerCase())) {
                        searches.push({ ...currentDoc.data(), id: currentDoc.id, is_default_enabled: is_default_enabled, searchorder: 'title1', categoryTitle: category.data().category_title, })
                      }

                      if (currentDoc.data().article_title.toLowerCase().includes(searchStr.toLowerCase())) {
                        searches.push({ ...currentDoc.data(), id: currentDoc.id, is_default_enabled: is_default_enabled, searchorder: 'title2', categoryTitle: category.data().category_title, })
                      }


                      searchQueryData.forEach((searchTerm) => {
                        if (currentDoc.data().article_title.toLowerCase().includes(searchTerm.toLowerCase().trim())) {
                          searches.push({ ...currentDoc.data(), id: currentDoc.id, is_default_enabled: is_default_enabled, searchorder: 'title3', categoryTitle: category.data().category_title, })
                        }

                        if (currentDoc.data().article_keywords.map(element => { return element.toLowerCase() }).includes(searchTerm.toLowerCase())) {
                          if (currentDoc.data().article_keywords.map(element => { return element.toLowerCase() }).filter((el) => el.includes(searchTerm.toLowerCase())).length > 0) {
                            searches.push({
                              ...currentDoc.data(), id: currentDoc.id, is_default_enabled: is_default_enabled, searchorder: 'keywords2', categoryTitle: category.data().category_title,
                              occurence: currentDoc.data().article_keywords.map(element => { return element.toLowerCase() }).filter((el) => el.includes(searchTerm.toLowerCase())).length
                            })
                          } else {
                            searches.push({ ...currentDoc.data(), id: currentDoc.id, is_default_enabled: is_default_enabled, searchorder: 'keywords1', categoryTitle: category.data().category_title, })
                          }
                        }

                        if (currentDoc.data().article_content.toLowerCase().includes(searchTerm.toLowerCase().trim())) {
                          searches.push({ ...currentDoc.data(), id: currentDoc.id, is_default_enabled: is_default_enabled, searchorder: 'content', categoryTitle: category.data().category_title, })
                        }
                      })

                      if (searchStr.toLocaleLowerCase().includes(currentDoc.data().article_title.toLowerCase())) {
                        searches.push({ ...currentDoc.data(), id: currentDoc.id, is_default_enabled: is_default_enabled, searchorder: 'title4', occurence: calcOcc(currentDoc.data().article_title.toLowerCase(), searchQueryData), categoryTitle: category.data().category_title, })
                      }

                      if (searchStr.toLocaleLowerCase().includes(currentDoc.data().article_content.toLowerCase())) {
                        searches.push({ ...currentDoc.data(), id: currentDoc.id, is_default_enabled: is_default_enabled, searchorder: 'content2', occurence: calcOcc(currentDoc.data().article_content.toLowerCase(), searchQueryData), categoryTitle: category.data().category_title, })

                      }
                    }
                  })
                }))
              }
            })

            await Promise.all(promises)
            searchResults.value = [
              ...searches.filter((el) => el.searchorder == 'title0'),
              ...searches.filter((el) => el.searchorder == 'title1'),
              ...searches.filter((el) => el.searchorder == 'title2'),
              ...searches.filter((el) => el.searchorder == 'title3'),
              ...searches.filter((el) => el.searchorder == 'title4').sort((a, b) => { return b.occurence - a.occurence }),
              ...searches.filter((el) => el.searchorder == 'keywords1'),
              ...searches.filter((el) => el.searchorder == 'keywords2').sort((a, b) => { return b.occurence - a.occurence }),
              ...searches.filter((el) => el.searchorder == 'content1'),
              ...searches.filter((el) => el.searchorder == 'content2').sort((a, b) => { return b.occurence - a.occurence })
            ];
            resolve(searchResults.value);
          })
        } catch (error) {
          // Reject with the error if there's any issue
          reject(error);
        }
      })
    })
  }

  const load_articles_and_categories = async (library_key, searchQuery = "") => {
    const user_support_library_id = library_key;
    hlpt_support_library_id = store.state.parent_lib_id;
    prevSearchQuery.value = searchQuery;
    try {

      let defaultDisableArticles = [];
      let disabled_default_categories = []
      let enabled_default_articles = []
      let enabled_default_categories = []
      let support_lib_ids = []
      if (!!hlpt_support_library_id) {
        support_lib_ids = [library_key, hlpt_support_library_id]
      } else {
        support_lib_ids = [library_key]
      }
      let defaultDisabledCategories = [];
      let AllDocs = projectFirestore.collection("Articles").where('is_published', '==', true).where('support_lib_id', 'in', support_lib_ids)

      const fetchDefaultDisabledCategories = () => {
        return new Promise((resolve, reject) => {
          if (hlpt_support_library_id) {
            projectFirestore.collection('Categories').where('support_lib_id', '==', hlpt_support_library_id).where('is_enabled', '==', false).get().then((catRes) => {
              if (!catRes.empty) {
                defaultDisabledCategories = catRes.docs.map((el) => el.id)
                resolve()
              } else {
                resolve()
              }
            })
          } else {
            resolve()
          }
        })
      }


      const processCategories = async () => {
        await fetchDefaultDisabledCategories();
      };
      processCategories()
      await projectFirestore.collection('SupportLibraries').doc(library_key).get().then(async (res) => {
        if (res.exists) {
          // console.log('res.data().disabled_default_articles;',res.data().disabled_default_articles)
          defaultDisableArticles = res.data().disabled_default_articles;
          if (res.data()?.disabled_default_categories) {
            disabled_default_categories = res.data().disabled_default_categories
          }
          if (res.data()?.enabled_default_articles) {
            enabled_default_articles = res.data().enabled_default_articles
          }
          if (res.data()?.enabled_default_categories) {
            enabled_default_categories = res.data().enabled_default_categories
          }



          if (res.data()?.categoryPositioning && res.data()?.categoryPositioning.length) {
            res.data().categoryPositioning.filter((el) => el.is_enabled == true).forEach((category) => {
              if (!defaultDisabledCategories.includes(category.id) && (category.is_disabled !== true || enabled_default_categories.includes(category.id))) {
                projectFirestore.collection("Articles").where('is_published', '==', true).where('support_lib_id', 'in', support_lib_ids).where('article_categories', '==', category.id).get().then(snap => {
                  let categorizedDocs = [];
                  if (category) {
                    let subCategory = {};
                    let subcategorizedDocs = []
                    // if (category.subCategory && category.subCategory.length > 0) {
                    category.subCategory.forEach((ele) => {
                      subCategory[ele.category_title] = { id: ele.id, articles: [] }
                      const subcategorizedDocs = []; // Create an empty array for each subcategory

                      if (ele.articles && ele.articles.length > 0) {
                        ele.articles.forEach((article) => {
                          AllDocs.get().then((res) => {
                            res.docs.forEach((subcurrentDoc, i) => {
                              var is_default_enabled = true;

                              if (
                                article === subcurrentDoc.id &&
                                !defaultDisableArticles.includes(article) &&
                                (subcurrentDoc.data().is_disabled !== true ||
                                  enabled_default_articles.includes(subcurrentDoc.id))
                              ) {
                                subcategorizedDocs.push({ ...subcurrentDoc.data(), id: subcurrentDoc.id, is_default_enabled: is_default_enabled, });
                              }
                            });

                            // Add articles to the subcategory only once, outside the inner loop
                            if (subcategorizedDocs.length > 0) {
                              subCategory[ele.category_title].articles = [...subcategorizedDocs];
                              subCategory[ele.category_title].icon = ele.category_icon || '';
                              subCategory[ele.category_title].description =
                                ele.category_description || '';
                            }
                          });
                        });
                      } else {
                        AllDocs.get().then((res) => {
                          res.docs.forEach((subcurrentDoc, i) => {
                            var is_default_enabled = true;

                            if (
                              ele.id === subcurrentDoc.data().article_categories &&
                              (subcurrentDoc.data().is_disabled !== true ||
                                enabled_default_articles.includes(subcurrentDoc.id)) &&
                              !defaultDisableArticles.includes(ele.id)
                            ) {
                              subcategorizedDocs.push({
                                ...subcurrentDoc.data(),
                                id: subcurrentDoc.id,
                                is_default_enabled: is_default_enabled,
                              });
                            }
                          });

                          // Add articles to the subcategory only once, outside the inner loop
                          if (subcategorizedDocs.length > 0) {
                            subCategory[ele.category_title].articles = [...subcategorizedDocs];
                            subCategory[ele.category_title].icon = ele.category_icon || '';
                            subCategory[ele.category_title].description =
                              ele.category_description || '';
                          }
                        });
                      }
                    });

                    // }

                    allCategoriesAndArticles.value[category.category_title] = { icon: category.category_icon ? category.category_icon : '', id: category.id, articles: [], description: category.category_description ? category.category_description : '', subCategory: subCategory }

                    category?.articles && category?.articles.forEach((el, idx) => {
                      snap.docs.forEach((currentDoc, i) => {
                        var is_default_enabled = true;
                        if (el == currentDoc.id && !defaultDisableArticles.includes(el) && (currentDoc.data().is_disabled !== true || enabled_default_articles.includes(currentDoc.id))) {
                          categorizedDocs.push({ ...currentDoc.data(), id: currentDoc.id, is_default_enabled: is_default_enabled });
                          allCategoriesAndArticles.value[category.category_title] = { icon: category.category_icon ? category.category_icon : '', id: category?.id, articles: [...categorizedDocs], subCategory: subCategory, description: category.category_description ? category.category_description : '' }
                        }
                      })
                    });

                    // console.log('allCategoriesAndArticles.value', subCategory)

                    //if articles id not included in category positioning categories
                    snap.docs.forEach((currentDoc, i) => {
                      var is_default_enabled = true;
                      if (!category?.articles?.includes(currentDoc.id) && !defaultDisableArticles.includes(currentDoc.id) && (currentDoc.data().is_disabled !== true || enabled_default_articles.includes(currentDoc.id))) {
                        categorizedDocs.push({ ...currentDoc.data(), subCategory: [], id: currentDoc?.id, is_default_enabled: is_default_enabled });
                        allCategoriesAndArticles.value[category.category_title] = { icon: category.category_icon ? category.category_icon : '', id: category?.id, articles: [...categorizedDocs], subCategory: subCategory, description: category.category_description ? category.category_description : '' }
                      }
                    })

                  } else {
                    snap.docs.forEach((currentDoc) => {
                      var is_default_enabled = true;
                      if (category.id == currentDoc.data().article_categories && !defaultDisableArticles.includes(category.id) && (currentDoc.data().is_disabled !== true || enabled_default_articles.includes(currentDoc.id))) {

                        categorizedDocs.push({ ...currentDoc.data(), id: currentDoc.id, is_default_enabled: is_default_enabled });
                        allCategoriesAndArticles.value[category.category_title] = { icon: ele.category_icon ? ele.category_icon : '', id: category?.id, articles: [...categorizedDocs], subCategory: [], description: ele.category_description ? category.category_description : '' }
                      }
                    })
                  }

                })
              }

            })
          } else {
            let categories = []
            const fetchCategories = () => {
              return new Promise((resolve, reject) => {
                projectFirestore.collection("Categories")
                  .where("support_lib_id", "in", support_lib_ids)
                  .where("is_enabled", "==", true)
                  .orderBy('category_title')
                  .get().then(snap => {
                    categories = snap.docs
                    resolve()
                  })
              })
            }

            await fetchCategories()
            categories.forEach((category) => {
              if (category?.data()?.category_title) {
                allCategoriesAndArticles.value[category.data().category_title] = { description: '', id: '', articles: [], subCategory: [] };
                projectFirestore.collection("Articles").where('is_published', '==', true).where('support_lib_id', 'in', support_lib_ids).where('article_categories', '==', category.id).get().then(snap => {
                  let categorizedDocs = [];
                  snap.docs.forEach((currentDoc) => {
                    if (!defaultDisabledCategories.includes(category.id) && (category.is_disabled !== true || enabled_default_categories.includes(category.id))) {
                      var is_default_enabled = true;
                      if (category.id == currentDoc.data().article_categories && !defaultDisableArticles.includes(category.id) && (currentDoc.data().is_disabled !== true || enabled_default_articles.includes(currentDoc.id))) {

                        categorizedDocs.push({ ...currentDoc.data(), id: currentDoc.id, is_default_enabled: is_default_enabled });
                        allCategoriesAndArticles.value[category.data().category_title] = { icon: category.category_icon ? category.category_icon : '', description: category.category_description, id: category?.id, articles: [...categorizedDocs], subCategory: [] }
                      }
                    }
                  })
                })
              }
            })
          }
        }

      })

    } catch (error) {
      this.$store.dispatch('printLog', {log:error});
    }
  }

  return {
    allCategoriesAndArticles,
    load_articles_and_categories,
    searchArticles,
    prevSearchQuery
  }
}

export default fgetUserAndHlptCategoriesAndArticles;

