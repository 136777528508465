<template>
  <div class="container-fluid ps-0 modal-chat-inbox position-fixed" :class="{
    'collapsed-left': isMenuCollapsed,
  }">
    <div class="row">
      <div class="pl-3 p-2 pt-0 pb-0 border-right bg-gray-light inbox-nav-width inbox-nav reporting-nav"
        :class="{ 'collapsed-right': isMenuCollapsed || $store.state.showConversationDetails_open == true }">
        <div class="row d-flex">
          <div class="col-12 py-3 p-2 pl-1 col10width">
            <div class="text-lg font-weight-bold d-flex align-items-center justify-content-between text-dark p-2 py-0">
              <div class="flex-grow-1">Reports</div>
              <div class="d-flex">
                <button class="text-gray-col-navlink btn p-2 py-1 mb-0 no-shadow"
                  @click="isModalVisible = !isModalVisible">
                  <i class="fa-sharp fa-light fa-chart-pie"></i>
                </button>
              </div>
            </div>
            <DomainLabel />
            <div class="chat-inbox-main-nav allshow" ref="reportsNavContainer">
              <ul class="list-unstyled text-sm pt-1 me-0 mb-0" id="main-inbox-group">
                <li class="py-2 p-1 mt-1 mb-1 d-flex align-items-center justify-content-between"
                  :class="{ active: $route.name === 'SupportOverviewStatic' }">
                  <router-link :to="{ name: 'SupportOverviewStatic' }"
                    class="text-col-navlink text-nowrap text-ellipsis w-100 text-bold">
                    <div class="d-flex align-items-center">
                      <i class="p-2 pt-0 pb-0 text-sm fa-solid fa-grid-2"></i>
                      <span class="p-2 py-0">Overview</span>
                    </div>
                  </router-link>
                </li>
                <li class="py-2 p-1 mt-1 mb-1 d-flex align-items-center justify-content-between cursor-pointer"
                  :class="{ active: $route.name === 'AllReportsStatic' && ($store.state.isYourReportsSelected == 'AllReportsStatic' || $store.state.isYourReportsSelected == '') && $route.name === 'AllReportsStatic' }"
                  @click="($store.state.isYourReportsSelected = 'AllReportsStatic', $router.push({ name: 'AllReportsStatic' }))">
                  <div class="text-col-navlink text-nowrap text-ellipsis w-100 text-bold">
                    <div class="d-flex align-items-center">
                      <i class="p-2 pt-0 pb-0 text-sm fa-solid fa-chart-simple"></i>
                      <span class="p-2 py-0">All reports</span>
                    </div>
                  </div>
                  <span class="msg-count p-1 py-0"> 14 </span>
                </li>
                <li class="py-2 p-1 mt-1 mb-1 d-flex align-items-center justify-content-between cursor-pointer"
                  :class="{ active: $store.state.isYourReportsSelected == 'YourReportsStatic' && $route.name === 'AllReportsStatic' }"
                  @click="($store.state.isYourReportsSelected = 'YourReportsStatic', $router.push({ name: 'AllReportsStatic' }))">
                  <div class="text-col-navlink text-nowrap text-ellipsis w-100 text-bold">
                    <div class="d-flex align-items-center">
                      <i class="p-2 pt-0 pb-0 text-sm fa-solid fa-circle-user"></i>
                      <span class="p-2 py-0">Your reports</span>
                    </div>
                  </div>
                  <span class="msg-count p-1 py-0"> 2 </span>
                </li>

              </ul>
              <div class="accordion accordion-flush" id="accordionReportsNav">
                <div class="accordion-item">
                  <div class="accordion-header" id="flush-headingOne">
                    <button class="accordion-button accordion-button-hover text-xs p-2 py-4" type="button"
                      data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false"
                      aria-controls="flush-collapseOne">
                      <div class="text-col-navlink text-nowrap text-ellipsis text-bold"><span
                          class="text-black mx-1 me-3"><i class="fa-solid fa-heart"></i></span>Your favorites</div>
                    </button>
                  </div>
                  <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionReportsNav">
                    <div class="accordion-body accordion-body-border border-0 p-0 mt-1">
                      <a class="text-col-navlink text-nowrap text-ellipsis">
                        <div class="d-flex align-items-center justify-content-between p-2 py-1 ps-4">
                          <div class="d-flex align-items-center">No reports added</div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <div class="accordion-header pt-1" id="flush-headingTwo">
                    <button class="accordion-button accordion-button-hover text-xs p-2" type="button"
                      data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false"
                      aria-controls="flush-collapseTwo">
                      <div class="text-col-navlink text-nowrap text-ellipsis text-bold"><span
                          class="text-black mx-1 me-3 bulb-ico"><i
                            class="fa-solid fa-lightbulb-exclamation-on"></i></span>Conversation topics</div>
                    </button>
                  </div>
                  <div id="flush-collapseTwo" class="accordion-collapse collapse" :class="{ show: isShowActiveCT }"
                    aria-labelledby="flush-headingTwo" data-bs-parent="#accordionReportsNav">
                    <div class="accordion-body accordion-body-border  p-0 mt-1">
                      <router-link :to="{ name: 'TopicsStatic' }" class="text-col-navlink text-nowrap text-ellipsis">
                        <li :class="{ active: $route.name === 'TopicsStatic' }"
                          class="d-flex align-items-center justify-content-between p-2 py-1 mt-1">
                          <div class="d-flex align-items-center">Topics</div>
                        </li>
                      </router-link>
                      <router-link :to="{ name: 'SuggestionsStatic' }"
                        class="text-col-navlink text-nowrap text-ellipsis">
                        <li :class="{ active: $route.name === 'SuggestionsStatic' }"
                          class="d-flex align-items-center justify-content-between p-2 py-1 mt-1">
                          <div class="d-flex align-items-center">Suggestions</div>
                        </li>
                      </router-link>
                    </div>
                  </div>
                </div>
                <!-- Accordion Item 2 -->
                <div class="border-bottom border-1 p-0 pt-2 m-3 my-2 border-white"></div>

                <div class="accordion-item">
                  <div class="accordion-header pt-2" id="flush-headingFour" @click="scrollToBottomNav">
                    <button class="accordion-button accordion-button-hover text-xs text-uppercase p-2" type="button"
                      data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false"
                      aria-controls="flush-collapseFour">
                      <div class="text-xs text-uppercase text-bold"><span class="text-sm mx-1 me-3"><i
                            class="fa-solid fa-folder-grid"></i></span>Human Support</div>
                    </button>
                  </div>
                  <div id="flush-collapseFour" class="accordion-collapse collapse show"
                    aria-labelledby="flush-headingFour" data-bs-parent="#accordionReportsNav"
                    :class="{ show: isShowActiveHS }">
                    <div class="accordion-body accordion-body-border  p-0 mt-1">
                      <router-link :to="{ name: 'ConversationsStatic' }"
                        class="text-col-navlink text-nowrap text-ellipsis">
                        <li :class="{ active: $route.name === 'ConversationsStatic' }"
                          class="d-flex align-items-center justify-content-between p-2 py-1 mt-1">
                          <div class="d-flex align-items-center">Conversations</div>
                        </li>
                      </router-link>
                      <router-link :to="{ name: 'ChatSupportStatic' }"
                        class="text-col-navlink text-nowrap text-ellipsis">
                        <li :class="{ active: $route.name === 'ChatSupportStatic' }"
                          class="d-flex align-items-center justify-content-between p-2 py-1 mt-1">
                          <div class="d-flex align-items-center">Chats</div>
                        </li>
                      </router-link>

                      <router-link :to="{ name: 'SupportTicketsStatic' }"
                        class="text-col-navlink text-nowrap text-ellipsis">
                        <li :class="{ active: $route.name === 'SupportTicketsStatic' }"
                          class="d-flex align-items-center justify-content-between p-2 py-1 mt-1">
                          <div class="d-flex align-items-center">Tickets</div>
                        </li>
                      </router-link>

                      <router-link :to="{ name: 'CustomerSatisfactionStatic' }"
                        class="text-col-navlink text-nowrap text-ellipsis">
                        <li :class="{ active: $route.name === 'CustomerSatisfactionStatic' }"
                          class="d-flex align-items-center justify-content-between p-2 py-1 mt-1">
                          <div class="d-flex align-items-center">Customer satisfication</div>
                        </li>
                      </router-link>
                    </div>
                  </div>
                </div>
                <!-- Accordion Item 3 -->
                <div class="accordion-item">
                  <div class="accordion-header pt-2" id="flush-headingFive">
                    <button class="accordion-button accordion-button-hover text-xs text-uppercase p-2" type="button"
                      data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false"
                      aria-controls="flush-collapseFive" @click="scrollToBottomNav">
                      <div class="text-xs text-uppercase text-bold"><span class="text-sm mx-1 me-3"><i
                            class="fa-solid fa-folder-grid"></i></span>Automation</div>
                    </button>
                  </div>

                  <div id="flush-collapseFive" class="accordion-collapse collapse" :class="{ show: isShowActiveAut }"
                    aria-labelledby="flush-headingFive" data-bs-parent="#accordionReportsNav">
                    <div class="accordion-body accordion-body-border mt-1 p-0">
                      <a class="text-col-navlink text-nowrap text-ellipsis">
                        <div class="d-flex align-items-center justify-content-between p-2 py-1 ps-4">
                          <div class="d-flex align-items-center">No reports added</div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <div class="accordion-header pt-2" id="flush-headingSix" @click="scrollToBottomNav">
                    <button class="accordion-button accordion-button-hover text-xs text-uppercase p-2" type="button"
                      data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false"
                      aria-controls="flush-collapseSix">
                      <div class="text-xs text-uppercase text-bold"><span class="text-sm mx-1 me-3"><i
                            class="fa-solid fa-folder-grid"></i></span>Proactive Support</div>
                    </button>
                  </div>
                  <div id="flush-collapseSix" class="accordion-collapse collapse show" :class="{ show: isShowActivePS }"
                    aria-labelledby="flush-headingSix" data-bs-parent="#accordionReportsNav">
                    <div class="accordion-body accordion-body-border mt-1 p-0">
                      <a class="text-col-navlink text-nowrap text-ellipsis">
                        <div class="d-flex align-items-center justify-content-between p-2 py-1 ps-4">
                          <div class="d-flex align-items-center">No reports added</div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DomainLabel from '@/components/custom/admin/DomainLabel'
export default {
  name: 'ReportsSidebarStatic',
  components: {
    DomainLabel
  },
  props: {
    darkMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isMenuCollapsed: false,
      
    };
  },
  methods: {
    toggleMenuCollapse() {
      this.isMenuCollapsed = !this.isMenuCollapsed;
    },
    scrollToBottomNav() {
      this.$nextTick(() => {
        setTimeout(() => {
          const container = this.$refs.reportsNavContainer;
          if (container) {
            container.scrollTop = container.scrollHeight + 50;
          }
        }, 300); 
      });
    },
  },
  mounted() {
    ////
  },
  computed: {
    isYourReportsSelected() {
      return this.$store.state.isYourReportsSelected;
    },
    isShowActiveCT() {
      let activeRoutes = ['TopicsStatic', 'SuggestionsStatic'];
      return activeRoutes.includes(this.$route.name);
    },
    isShowActiveHS() {
      let activeRoutes = [
        'OverviewStatic',
        'SupportOverviewStatic',
        'ConversationsStatic',
        'ChatSupportStatic',
        'CustomerSatisfactionStatic',
        'SupportTicketsStatic',
      ];
      return activeRoutes.includes(this.$route.name);
    },
    isShowActiveAut() {
      let activeRoutes = ['ConversationalSupportStatic', 'ModalAIAgentStatic', 'AutomationReportStatic'];
      return activeRoutes.includes(this.$route.name);
    },
    isShowActivePS() {
      let activeRoutes = ['KnowledgebaseReportStatic', 'CoursesReportStatic', 'SOPsReportStatic', 'NewsFlashReportStatic'];
      return activeRoutes.includes(this.$route.name);
    },
  },
  watch: {
    isYourReportsSelected(newValue, oldValue) {
    },
  },
 
};
</script>
<style scoped>

</style>