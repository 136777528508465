import { createStore } from 'vuex';
import bootstrap from 'bootstrap/dist/js/bootstrap.min.js';
import router from '@/router';
import moment from 'moment';
import Api from '@/views/admin/Api';
import { useRoute, useRouter } from 'vue-router';
const getParentLibId = () => {
  let locationUrl = new URL(document.location.href);
  if (locationUrl.searchParams.get('p')) {
    return locationUrl.searchParams.get('p');
  } else {
    return localStorage.getItem('parent_lib_id');
  }
};

const areThirdPartyCookiesEnabled = () => {
  // Try to set a third-party cookie
  document.cookie = 'testCookie=test; samesite=None; secure';

  // Check if the cookie was set successfully
  var cookiesEnabled = document.cookie.indexOf('testCookie') !== -1;

  // Clean up by deleting the test cookie
  document.cookie = 'testCookie=; expires=Thu, 01 Jan 1970 00:00:00 UTC; samesite=None; secure';

  return cookiesEnabled;
};
export default createStore({
  state: {
    isAgentProfileNotCompleted: false,
    showAgentProfileAlert: false,
    isChildLibrariesLoading: false,
    isSelectedChildAgency: null,
    hideConfigButton: false,
    isPinned: true,
    showConfig: false,
    isRTL: false,
    color: '',
    sidebarType: 'bg-white',
    darkMode: false,
    isNavFixed: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: true,
    showNavbar: true,
    showFooter: true,
    showMain: true,
    layout: 'default',
    siteCopyRight: '',
    childLibraryList: [],
    assigned_lib_id: localStorage.getItem('parent_lib_id') == process.env.VUE_APP_HLPT_PARENT_SUPPORT_LIB_ID ? localStorage.getItem('parent_lib_id') : '',

    user_id: localStorage.getItem('user_id'),
    support_lib_id: localStorage.getItem('support_Lib_id'),
    ticketStatusUpdatingRef: '',

    newReport: null,

    selectedChildAgencyFilter: '',
    agent_picture: '',
    frontendUnreadMsgs: 0,
    queueMembers: [],
    inboxMembers: [],
    messages: {},
    selectedChatBefore: null,
    selectedChat: null,
    selectedOngoingChat: null,
    selectedArchive: null,
    selectedTicket: null,
    selectedOtherTicket: null,
    selectedMentionTicket: null,
    selectedCreatedByYouTicket: null,
    selectedReassignedTicket: null,
    selectedUnassignedTicket: null,
    selectedAwaitingReplyTicket: null,
    selectedHlptTicket: null,
    selectedAwaitingReplyTicket: null,
    isSelectedChatLoading: false,
    isSelectedArchiveLoading: false,
    isSelectedTicketLoading: false,
    isSelectedOtherTicketLoading: false,
    isSelectedMentionTicketLoading: false,
    isSelectedCreatedByYouTicketLoading: false,
    isSelectedReassignedTicketLoading: false,
    isSelectedUnassignedTicketLoading: false,
    isSelectedAwaitingReplyTicketLoading: false,
    isSelectedSearchLoading: false,
    isSelectedOtherHlptTicketLoading: false,
    isSelectedAwaitingReplyHlptTicketLoading: false,
    isAiWidget: 0,

    openIntegratedChats: false,

    socket: '',
    scrollToBottom: false,
    display_name: '',
    isticketCreating: false,
    isticketTypeCreating: false,
    isDirectTicketCreating: false,

    isArchiveSearch: false,
    searchArchive: false,
    isArchiveSearching: false,
    archiveSortByFilter: 'newest',
    archiveEmailFilter: '',
    archivePerpage: 20,
    allChatsSortByFilter: '',
    archiveChatsTotalRecords: 0,
    archiveChatsPage: 1,
    archiveChats: [],

    inboxChatsType: 'all',

    isDisplayInboxChats: true,
    isDisplaySuperviseChats: true,

    superviseChats: {},
    allOnGoingChats: [],
    isSuperviseChatsLoading: true,
    isAllOnGoingChatsLoading: true,

    ticketsTotalRecords: 0,
    ticketsPage: 1,
    isTicketSearch: false,
    searchTicket: false,
    isTicketSearching: false,
    ticketSortByFilter: 'newest',
    ticketEmailFilter: '',
    ticketStatusByFilter: '',
    ticketCategoryFilter: '',
    ticketTierFilter: '',
    ticketCreateMethodFilter: '',
    ticketRefFilter: '',
    ticketCreateStatusFilter: '',
    ticketsPerpage: 20,
    tickets: [],
    fetchTickets: false,
    isLoadingTickets: false,

    otherTicketsTotalRecords: 0,
    otherTicketsPage: 1,
    isOtherTicketSearch: false,
    searchOtherTicket: false,
    isOtherTicketSearching: false,
    otherTicketSortByFilter: 'newest',
    otherTicketEmailFilter: '',
    otherTicketStatusByFilter: '',
    otherTicketCategoryFilter: '',
    otherTicketTierFilter: '',
    otherTicketCreateMethodFilter: '',
    otherTicketRefFilter: '',
    otherTicketCreateStatusFilter: '',
    otherTicketsPerpage: 20,
    otherTickets: [],
    fetchOtherTickets: false,
    isLoadingOtherTickets: false,

    hlptTicketsTotalRecords: 0,
    hlptTicketsPage: 1,
    isHlptTicketSearch: false,
    searchHlptTicket: false,
    isHlptTicketSearching: false,
    hlptTicketSortByFilter: 'newest',
    hlptTicketEmailFilter: '',
    hlptTicketStatusByFilter: '',
    hlptTicketCategoryFilter: '',
    hlptTicketTierFilter: '',
    hlptTicketCreateMethodFilter: '',
    hlptTicketRefFilter: '',
    hlptTicketCreateStatusFilter: '',
    hlptTicketsPerpage: 20,
    hlptTickets: [],
    fetchHlptTickets: false,
    isLoadingHlptTickets: false,

    mentionTicketsTotalRecords: 0,
    mentionTicketsPage: 1,
    isMentionTicketSearch: false,
    searchMentionTicket: false,
    isMentionTicketSearching: false,
    mentionTicketSortByFilter: 'newest',
    mentionTicketEmailFilter: '',
    mentionTicketStatusByFilter: '',
    mentionTicketCategoryFilter: '',
    mentionTicketTierFilter: '',
    mentionTicketCreateMethodFilter: '',
    mentionTicketRefFilter: '',
    mentionTicketCreateStatusFilter: '',
    mentionTicketsPerpage: 20,
    mentionTickets: [],
    mentionFilterBy: 'unread',
    fetchMentionTickets: false,
    isLoadingMentionTickets: false,

    createdByYouTicketsTotalRecords: 0,
    createdByYouTicketsPage: 1,
    isCreatedByYouTicketSearch: false,
    searchCreatedByYouTicket: false,
    isCreatedByYouTicketSearching: false,
    createdByYouTicketSortByFilter: 'newest',
    createdByYouTicketEmailFilter: '',
    createdByYouTicketStatusByFilter: '',
    createdByYouTicketCategoryFilter: '',
    createdByYouTicketTierFilter: '',
    createdByYouTicketCreateMethodFilter: '',
    createdByYouTicketRefFilter: '',
    createdByYouTicketCreateStatusFilter: '',
    createdByYouTicketsPerpage: 20,
    createdByYouTickets: [],
    fetchCreatedByYouTickets: false,
    isLoadingCreatedByYouTickets: false,

    reassignedTicketsTotalRecords: 0,
    reassignedTicketsPage: 1,
    isReassignedTicketSearch: false,
    searchReassignedTicket: false,
    isReassignedTicketSearching: false,
    reassignedTicketSortByFilter: 'newest',
    reassignedTicketEmailFilter: '',
    reassignedTicketStatusByFilter: '',
    reassignedTicketCategoryFilter: '',
    reassignedTicketTierFilter: '',
    reassignedTicketCreateMethodFilter: '',
    reassignedTicketRefFilter: '',
    reassignedTicketCreateStatusFilter: '',
    reassignedTicketsPerpage: 20,
    reassignedTickets: [],
    fetchReassignedTickets: false,
    isLoadingReassignedTickets: false,

    unassignedTicketsTotalRecords: 0,
    unassignedTicketsPage: 1,
    isUnassignedTicketSearch: false,
    searchUnassignedTicket: false,
    isUnassignedTicketSearching: false,
    unassignedTicketSortByFilter: 'newest',
    unassignedTicketEmailFilter: '',
    unassignedTicketStatusByFilter: '',
    unassignedTicketCategoryFilter: '',
    unassignedTicketTierFilter: '',
    unassignedTicketCreateMethodFilter: '',
    unassignedTicketRefFilter: '',
    unassignedTicketCreateStatusFilter: '',
    unassignedTicketsPerpage: 20,
    unassignedTickets: [],
    fetchUnassignedTickets: false,
    isLoadingUnassignedTickets: false,

    awaitingReplyTicketsTotalRecords: 0,
    awaitingReplyTicketsPage: 1,
    isAwaitingReplyTicketSearch: false,
    searchAwaitingReplyTicket: false,
    isAwaitingReplyTicketSearching: false,
    awaitingReplyTicketSortByFilter: 'newest',
    awaitingReplyTicketEmailFilter: '',
    awaitingReplyTicketStatusByFilter: '',
    awaitingReplyTicketCategoryFilter: '',
    awaitingReplyTicketTierFilter: '',
    awaitingReplyTicketCreateMethodFilter: '',
    awaitingReplyTicketRefFilter: '',
    awaitingReplyTicketCreateStatusFilter: '',
    awaitingReplyTicketsPerpage: 20,
    awaitingReplyTickets: [],
    fetchAwaitingReplyTickets: false,
    isLoadingAwaitingReplyTickets: false,

    draftsTotalRecords: 0,
    isSelectedDraftLoading: false,
    draftsPage: 1,
    draftsPerPage: 20,
    fetchDrafts: false,
    fetchDraftsList: false,
    isLoadingDrafts: false,
    drafts: [],
    draftReviewFor: 'self',
    draftReviewStatus: '',
    selectedDraft: null,
    draftEmailFilter: '',
    isDraftSearch: false,
    isDraftSearching: false,
    searchDraft: false,

    awaitingReplyHlptTicketsTotalRecords: 0,
    awaitingReplyHlptTicketsPage: 1,
    isAwaitingReplyHlptTicketSearch: false,
    searchAwaitingReplyHlptTicket: false,
    isAwaitingReplyHlptTicketSearching: false,
    awaitingReplyHlptTicketSortByFilter: 'newest',
    awaitingReplyHlptTicketEmailFilter: '',
    awaitingReplyHlptTicketStatusByFilter: '',
    awaitingReplyHlptTicketCategoryFilter: '',
    awaitingReplyHlptTicketTierFilter: '',
    awaitingReplyHlptTicketCreateMethodFilter: '',
    awaitingReplyHlptTicketRefFilter: '',
    awaitingReplyHlptTicketCreateStatusFilter: '',
    awaitingReplyHlptTicketsPerpage: 20,
    awaitingReplyHlptTickets: [],
    fetchAwaitingReplyHlptTickets: false,
    isLoadingAwaitingReplyHlptTickets: false,

    fetchByTicketConversationRef: '',

    searchTotalRecords: 0,
    searchPage: 1,
    isSearch: false,
    searchTicket: false,
    isSearching: false,
    searchSortByFilter: '',
    searchEmailFilter: '',
    searchStatusByFilter: '',
    searchCategoryFilter: '',
    searchTagFilter: '',
    searchTierFilter: '',
    searchCreateMethodFilter: '',
    searchTicketRefFilter: '',
    searchCreateStatusFilter: '',
    searchPerpage: 20,
    searchRecords: [],
    searchCompanyFilter: null,
    searchCtFromFilter: '',
    searchCtToFilter: '',
    searchCustomerNameFilter: '',
    searchCustomerEmailFilter: '',
    filterSearchCompanies: '',
    filterSearchTeammates: '',
    filterSearchCategory: '',
    filterSearchTag: '',
    searchDateFilter: '',
    searchDateSelectedFilter: '',
    searchFilterType: '',
    searchTicketConversationRefFilter: '',
    selectedSearch: {},
    searchQuery: '',
    searchTicketAssignedTo: '',
    searchCSATFilter: [],
    searchDate: [],
    initSearchRecords: false,
    fetchSearchRecords: false,
    searchAdditionalFilters: [],

    assignmentAgentList: [],
    isAssignmentAgentListLoading: [],

    isChatTakeOverProcessing: false,
    isTicketAssignmentLoading: false,

    isAddingLeadTags: false,
    isLoadingLeadTags: false,
    tags: [],
    isLoadingArchieve: false,
    is_away: null,
    show_alert: false,
    awayAgents: [],
    agentModeUpdated: false,

    isThreadLoading: false,

    selectedChats: [],

    //frontend chat
    isModalChat: false,
    chatStarted: false,
    previousChat: [],
    frontendMessages: [],
    connection_id: '',
    chatEnded: false,
    widgetChatForm: false,
    companyName: 'Chat',
    frontendCompanyName: 'Knowledge Base',
    companyLogo: '',
    visitor_name: areThirdPartyCookiesEnabled() ? localStorage.getItem('visitor_name') : '',
    visitor_email: areThirdPartyCookiesEnabled() ? localStorage.getItem('visitor_email') : '',
    visitor_question: '',
    conv_rating: '',
    isConnecting: false,
    isTyping: false,
    areThirdPartyCookiesEnabled: false,
    visitor_connection_conversation_ref: '',
    visitor_connection_agent_ref: '',
    visitor_connection_queue_ref: '',
    visitor_connection_device_ref: '',
    visitor_connection_visitor_ref: '',
    visitor_connection_visitor_con_id: '',
    fetchArchives: false,
    agent_name: '',
    agentList: [],
    isPreviousMessagesFetching: false,
    isMarkingSeen: false,
    markAsSeenTimeOut: null,

    showCompactBottomToolbar: true,

    widgetDashboard: null,
    widgetMessages: null,
    widgetContactus: null,
    widgetHelplibrary: null,
    widgetNewsfeed: null,

    isOtherTicketMenuLinkFlagged: false,
    isArchivesMenuLinkFlagged: false,
    isHlptTicketMenuLinkFlagged: false,
    isAwaitingHlptTicketMenuLinkFlagged: false,

    advancedWidgetIndex: 'HomeAdvancedDashboard',
    advancedWidgetActiveRoute: 'HomeAdvancedDashboard',
    compactWidgetActiveRoute: 'HomeAdvancedDashboard',
    customLinkData: null,
    selectAllRecords: false,
    isUserPermissionsLoading: true,
    queueListView: false,
    userPermissions: {
      canManageGeneralAndSecuritySettings: true,
      canManageTeammatesSeatsAndPermissions: true,
      canChangeTeammatesStatus: true,
      canManageMessengerSettings: true,
      canManageWidgetSettings: true,
      canManageTicketSettings: true,
      canManageChatSettings: true,
      canCreateAndUpdateHelpCenterArticles: true,
      canManageAndPublishHelpCenterArticles: true,
      canCreateAndUpdateNewsArticles: true,
      canManageAndPublishNewsArticles: true,
      canCreateAndUpdateCoursesArticles: true,
      canManageAndPublishCoursesArticles: true,
      canCreateAndUpdateSopArticles: true,
      canManageAndPublishSopArticles: true,
      canTakeChats: true,
      canSuperviseChats: true,
      canViewChatArchives: true,
      canReplyToTickets: true,
      canViewExistingTickets: true,
      canCreateAndEditAnAutomation: true,
      canInviteNewUsers: true,
      canUpdateUserRoles: true,
      canReviewOtherDrafts: true,
      canSubmitTicketForReview: true,
      canDeleteOrReassignOwner: true,
      canCreateAndEditForms: true,
      canCreateAndEditReports: true,
      canViewReports: true,
      canViewForms: true,
      canViewAutomations: true,
      canCreateAndEditIntegrations: true,
      canViewIntegrations: true,
      canAssignChats: true,
      canDeleteAnAutomation: true,
      canCreateAndEditSmartQueue: true,
      canDeleteSmartQueue: true,
    },

    agent_role: localStorage.getItem('user_type'),

    renderChart: false,
    showConversationDetails_open: false,
    customFormRef: false,
    selected_contact_form_ref: '',
    fetchTeams: false,
    customFormFields: [],
    isCustomFormLoading: false,
    customFormBuilderObject: null,

    chat_ref_uid: '',
    chat_ended_by: '',
    showToast: {},
    printConsoleLogs: {},
    enable_inbox_notifications: true,

    draft_messages: [],
    draft_statuses: [],

    parent_lib_id: getParentLibId(),
    bootstrap,
    lastFailedArticleSearch: {
      id: '',
    },
    lastSuccessArticleSearch: {
      id: '',
    },
    searchedKeyword: {
      query: '',
    },
    isYourReportsSelected: '',
    conv_ticket_rating: '',

    autoAssignedChatThreads: [],
    showAutoAssignedChatAlert: false,
    autoAssignedChatThreadsInterval: null,

    manualAssignedChatThreads: [],
    showManualAssignedChatAlert: false,
    manualAssignedChatThreadsInterval: null,

    assignment_wait_time: 1,

    chatClosedByUser: false,

    queueOrderBy: '',
    queueOrder: '',

    engageOrderBy: '',
    engageOrder: '',

    queueMembersLoading: [],

    smartSearchLists: [],

    engageListView: false,
    renderChats: {
      type: '',
      key: 1,
    },

    // Add new reports state
    reports: {
      allReports: [],
      customReports: [],
      favoriteReports: [],
      HSIncludedReports: [],
      PSIncludedReports: [],
      hasHS: false,
      hasPS: false,
      totalReports: 0,
      totalCustomReports: 0,
      lastUpdated: null,
    },
  },
  mutations: {
    rerenderChats(state, { type }) {
      // console.log('rerenderChats ........')
      state.renderChats = {
        type: type,
        key: state.renderChats + 1,
      };
    },
    updateSelectedItem(state, { key, payload }) {
      state[key] = payload;
    },
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },
    navbarMinimize(state) {
      const sidenav_show = document.querySelector('#app');

      if (sidenav_show.classList.contains('g-sidenav-show') && sidenav_show.classList.contains('g-sidenav-hidden')) {
        sidenav_show.classList.add('g-sidenav-pinned');
        sidenav_show.classList.remove('g-sidenav-hidden');
        state.isPinned = true;
      } else if (sidenav_show.classList.contains('g-sidenav-show') && sidenav_show.classList.contains('g-sidenav-pinned')) {
        sidenav_show.classList.add('g-sidenav-hidden');
        sidenav_show.classList.remove('g-sidenav-pinned');
        state.isPinned = false;
      } else if (sidenav_show.classList.contains('g-sidenav-show') && window.innerWidth < 1200) {
        sidenav_show.classList.add('g-sidenav-pinned');
        state.isPinned = true;
      } else {
        sidenav_show.classList.add('g-sidenav-hidden');
        state.isPinned = true;
      }
    },
    setSidebarType(state, payload) {
      state.sidebarType = payload;
    },
    navbarFixed(state) {
      if (state.isNavFixed === false) {
        state.isNavFixed = true;
      } else {
        state.isNavFixed = false;
      }
    },
    toggleDefaultLayout(state) {
      state.showNavbar = !state.showNavbar;
      state.showSidenav = !state.showSidenav;
      state.showFooter = !state.showFooter;
    },
    setLastFailedArticleSearch(state, value) {
      state.lastFailedArticleSearch.id = value;
    },
    setLastSuccessArticleSearch(state, value) {
      state.lastSuccessArticleSearch.id = value;
    },
    setSearchedKeyword(state, value) {
      state.searchedKeyword.query = value;
    },
    setShowConversationDetails(state, value) {
      state.showConversationDetails_open = value;
    },

    scrollToBottom(state, value) {
      const container = document.getElementById('conversation-window');
      const params = new URLSearchParams(window.location.search);
      const scrollToBottom = () => {
        if (container) {
          container.scrollTop = container.scrollHeight;
        }
      };
      const getUnreadMessages = () => {
        const tabs = {
          inbox: { selectedItem: state.selectedChat, storeKey: 'messages' },
          search: { selectedItem: state.selectedSearch, storeKey: 'selectedSearch' },
          archives: { selectedItem: state.selectedArchive, storeKey: 'selectedArchive' },
          tickets: { selectedItem: state.selectedTicket, storeKey: 'selectedTicket' },
          mentiontickets: { selectedItem: state.selectedMentionTicket, storeKey: 'selectedMentionTicket' },
          hlpttickets: { selectedItem: state.selectedHlptTicket, storeKey: 'selectedHlptTicket' },
          createdbyyoutickets: { selectedItem: state.selectedCreatedByYouTicket, storeKey: 'selectedCreatedByYouTicket' },
          othertickets: { selectedItem: state.selectedOtherTicket, storeKey: 'selectedOtherTicket' },
          reassignedtickets: { selectedItem: state.selectedReassignedTicket, storeKey: 'selectedReassignedTicket' },
          unassignedtickets: { selectedItem: state.selectedUnassignedTicket, storeKey: 'selectedUnassignedTicket' },
          awaitingreplytickets: { selectedItem: state.selectedAwaitingReplyTicket, storeKey: 'selectedAwaitingReplyTicket' },
          awaitingreplyhlpttickets: { selectedItem: state.selectedAwaitingReplyHlptTicket, storeKey: 'selectedAwaitingReplyHlptTicket' },
          drafts: { selectedItem: state.selectedDraft, storeKey: 'selectedDraft' },
        };

        const currentTab = params.get('tab');
        if (!currentTab || !tabs[currentTab]?.selectedItem) return;

        let selectedItem = tabs[currentTab].selectedItem;
        if (!selectedItem?.conversation_messages && !(currentTab === 'inbox' && state.messages[params.get('conversation_ref')])) return;

        let msgs = currentTab === 'inbox' ? (state.messages[params.get('conversation_ref')] ? JSON.parse(JSON.stringify(state.messages[params.get('conversation_ref')])) : []) : JSON.parse(JSON.stringify(selectedItem.conversation_messages)) || [];
        msgs = msgs.filter(el => el.sender_type !== 'agent' && el.read_status == 0);
        // console.log('msgs ', msgs)
        return msgs;
      };
      if (params.get('msgFocus')) {
        const msgFocus = params.get('msgFocus');
        if (container && msgFocus) {
          const target = document.querySelector(`#${msgFocus}`);
          if (target) {
            target.classList.add('highlight-effect'); // Add initial highlight effect
            // Use setTimeout to remove the highlight after a short delay
            setTimeout(() => {
              target.classList.add('highlight-effect-fade'); // Start fading the highlight
              setTimeout(() => {
                target.classList.remove('highlight-effect', 'highlight-effect-fade'); // Clean up classes
              }, 1000); // Remove classes after the transition ends
            }, 500); // Delay before starting the fade
            const scrollPosition = target.offsetTop;
            container.scrollTop = scrollPosition;
          }
        }
        setTimeout(() => {
          state.isThreadLoading = false;
        }, 1000);
      } else {
        // console.log('going in else')
        // console.log('state.isPreviousMessagesFetching ', state.isPreviousMessagesFetching)
        // console.log('state.isThreadLoading ', state.isThreadLoading)
        if (!state.isPreviousMessagesFetching) {
          // console.log('getUnreadMessages()?.length ', getUnreadMessages())
          if (getUnreadMessages()?.length > 0 && state.isThreadLoading) {
            const target = document.querySelector(`#unread_flag_${getUnreadMessages()[0].id}`);
            // console.log('container ', container)
            // console.log('target ', target)
            if (target) {
              const scrollPosition = target.offsetTop;
              container.scrollTop = scrollPosition;
            }
            setTimeout(() => {
              state.isThreadLoading = false;
            }, 1000);
          } else {
            // console.log('calling')
            setTimeout(() => {
              scrollToBottom();
            }, 100);
            setTimeout(() => {
              state.isThreadLoading = false;
            }, 1000);
          }
        }
      }
    },

    seenMessages(state, value) {
      if (document.hidden) return;
      const tabs = {
        inbox: { messages: state.messages, selectedItem: state.selectedChat, individualKey: 'selectedChat', storeKey: 'inboxMembers' },
        tickets: { messages: state.selectedTicket?.conversation_messages, selectedItem: state.selectedTicket, individualKey: 'selectedTicket', storeKey: 'tickets' },
        archives: { messages: state.selectedArchive?.conversation_messages, selectedItem: state.selectedArchive, individualKey: 'selectedArchive', storeKey: 'archiveChats' },
        mentiontickets: { messages: state.selectedMentionTicket?.conversation_messages, selectedItem: state.selectedMentionTicket, individualKey: 'selectedMentionTicket', storeKey: 'mentionTickets' },
        createdbyyoutickets: { messages: state.selectedCreatedByYouTicket?.conversation_messages, selectedItem: state.selectedCreatedByYouTicket, individualKey: 'selectedCreatedByYouTicket', storeKey: 'createdByYouTickets' },
        drafts: { messages: state.selectedDraft?.conversation_messages, selectedItem: state.selectedDraft, individualKey: 'selectedDraft', storeKey: 'drafts' },
        search: { messages: state.selectedSearch?.conversation_messages, selectedItem: state.selectedSearch, individualKey: 'selectedSearch', storeKey: 'searchRecords' },
        hlpttickets: { messages: state.selectedHlptTicket?.conversation_messages, selectedItem: state.selectedHlptTicket, individualKey: 'selectedHlptTicket', storeKey: 'hlptTickets' },
        othertickets: { messages: state.selectedOtherTicket?.conversation_messages, selectedItem: state.selectedOtherTicket, individualKey: 'selectedOtherTicket', storeKey: 'otherTickets' },
        reassignedtickets: { messages: state.selectedReassignedTicket?.conversation_messages, selectedItem: state.selectedReassignedTicket, individualKey: 'selectedReassignedTicket', storeKey: 'reassignedTickets' },
        unassignedtickets: { messages: state.selectedUnassignedTicket?.conversation_messages, selectedItem: state.selectedUnassignedTicket, individualKey: 'selectedUnassignedTicket', storeKey: 'unassignedTickets' },
        awaitingreplytickets: { messages: state.selectedAwaitingReplyTicket?.conversation_messages, selectedItem: state.selectedAwaitingReplyTicket, individualKey: 'selectedAwaitingReplyTicket', storeKey: 'awaitingReplyTickets' },
        awaitingreplyhlpttickets: { messages: state.selectedAwaitingReplyHlptTicket?.conversation_messages, selectedItem: state.selectedAwaitingReplyHlptTicket, individualKey: 'selectedAwaitingReplyHlptTicket', storeKey: 'awaitingReplyHlptTickets' },
      };

      const params = new URLSearchParams(window.location.search);

      const currentTab = params.get('tab');
      // console.log('currentTab ', currentTab)
      const conversationRef = params.get('conversation_ref');
      if (!currentTab || !conversationRef || !tabs[currentTab]?.selectedItem) return;

      let selectedItem = tabs[currentTab].selectedItem;
      // console.log('selectedItem ', selectedItem)
      let individualKey = tabs[currentTab].individualKey;
      // console.log('individualKey ', individualKey)
      if (currentTab === 'inbox' ? !(tabs[currentTab].messages[conversationRef] && tabs[currentTab].messages[conversationRef].length) : !(selectedItem.conversation_messages && selectedItem.conversation_messages.length)) {
        return;
      }

      let msgs = currentTab === 'inbox' ? state.messages[conversationRef].filter(el => (el.sender_type !== 'agent' || el.sender_type == 'note') && el.read_status === 0) : selectedItem.conversation_messages.filter(el => el.sender_type !== 'agent' && el.read_status === 0) || [];
      // console.log('msgs length', msgs)
      if (!msgs.length) return;

      let last_msg = msgs[msgs.length - 1];
      if (last_msg.read_status === 1) return;

      let message_id = last_msg?.id;
      if (!message_id) return;

      let payload = {
        action: 'messageUpdate',
        message_action: 'mark_as_seen',
        support_lib_id: state.support_lib_id,
        message_id,
        conversation_ref: selectedItem.conversation_ref,
        sender_type: 'agent',
      };

      // console.log('payload ', payload)

      if (state.socket) {
        state.isMarkingSeen = true;
        Api.sendApplicationWebSocket(payload);
        if (state.markAsSeenTimeOut) {
          clearTimeout(state.markAsSeenTimeOut);
          state.markAsSeenTimeOut = null;
        }
        state.markAsSeenTimeOut = setTimeout(() => {
          let storeKey = tabs[currentTab].storeKey;
          let index = state[storeKey].findIndex(el => el.conversation_ref == conversationRef);
          if (index !== -1) {
            let stateData = JSON.parse(JSON.stringify(state[storeKey]));
            stateData[index].unread_messages_count = 0;
            stateData[index] = {
              ...state[storeKey][index],
              unread_messages_count: 0,
              conversation_messages: currentTab === 'inbox' ? [] : state[storeKey][index].conversation_messages.map(el => ({ ...el, read_status: 1 })),
            };
            state[storeKey] = stateData;
          }
          if (currentTab === 'inbox') {
            state.messages[conversationRef].forEach(el => {
              if (el.read_status == 0) {
                el.read_status = 1;
              }
            });
          } else {
            if (state[individualKey].conversation_messages) {
              state[individualKey] = {
                ...selectedItem,
                unread_messages_count: 0,
                conversation_messages: state[individualKey].conversation_messages.map(el => ({ ...el, read_status: 1 })),
              };
            }
          }

          state.markAsSeenTimeOut = null;
          setTimeout(() => {
            state.isMarkingSeen = false;
          }, 1000);
        }, 2000);
      }
    },

    removeToolTip() {
      const tooltip = document.querySelector('.tooltip');
      if (tooltip) {
        tooltip.remove();
      }
    },

    disableParentScroll() {
      document.body.style.overflow = 'hidden'; // Disable parent scrolling
    },
    enableParentScroll() {
      document.body.style.overflow = ''; // Enable parent scrolling again
    },

    // Add new reports mutations
    setReports(state, payload) {
      state.reports = {
        ...state.reports,
        ...payload,
        lastUpdated: new Date().getTime(),
      };
    },

    clearReports(state) {
      state.reports = {
        allReports: [],
        customReports: [],
        favoriteReports: [],
        HSIncludedReports: [],
        PSIncludedReports: [],
        hasHS: false,
        hasPS: false,
        totalReports: 0,
        totalCustomReports: 0,
        lastUpdated: null,
      };
    },

    // Optional: Add individual report mutations if needed
    updateReport(state, { reportId, data }) {
      const reportIndex = state.reports.allReports.findIndex(r => r.report_id === reportId);
      if (reportIndex !== -1) {
        state.reports.allReports[reportIndex] = {
          ...state.reports.allReports[reportIndex],
          ...data,
        };
      }
    },

    addReport(state, report) {
      state.reports.allReports.push(report);
      state.reports.totalReports++;
      if (report.report_type === 1) {
        state.reports.customReports.push(report);
        state.reports.totalCustomReports++;
      }
      if (report.report_favorite === 1) {
        state.reports.favoriteReports.push(report);
      }
      // Update HS/PS lists
      if (report.report_route === 'KnowledgebaseReport') {
        state.reports.PSIncludedReports.push(report);
        state.reports.hasPS = true;
      } else {
        state.reports.HSIncludedReports.push(report);
        state.reports.hasHS = true;
      }
    },

    removeReport(state, reportId) {
      // Remove from all relevant arrays
      state.reports.allReports = state.reports.allReports.filter(r => r.report_id !== reportId);
      state.reports.customReports = state.reports.customReports.filter(r => r.report_id !== reportId);
      state.reports.favoriteReports = state.reports.favoriteReports.filter(r => r.report_id !== reportId);
      state.reports.HSIncludedReports = state.reports.HSIncludedReports.filter(r => r.report_id !== reportId);
      state.reports.PSIncludedReports = state.reports.PSIncludedReports.filter(r => r.report_id !== reportId);

      // Update counts and flags
      state.reports.totalReports = state.reports.allReports.length;
      state.reports.totalCustomReports = state.reports.customReports.length;
      state.reports.hasHS = state.reports.HSIncludedReports.length > 0;
      state.reports.hasPS = state.reports.PSIncludedReports.length > 0;
    },

    toggleReportFavorite(state, { reportId, isFavorite }) {
      const report = state.reports.allReports.find(r => r.report_id === reportId);
      if (report) {
        report.report_favorite = isFavorite ? 1 : 0;
        if (isFavorite) {
          state.reports.favoriteReports.push(report);
        } else {
          state.reports.favoriteReports = state.reports.favoriteReports.filter(r => r.report_id !== reportId);
        }
      }
    },
  },
  actions: {
    updateShowConversationDetails({ commit }, value) {
      commit('setShowConversationDetails', value);
    },
    toggleSidebarColor({ commit }, payload) {
      commit('setSidebarType', payload);
    },
    async updateLastFailedArticleSearch(context, payload) {
      context.commit('setLastFailedArticleSearch', payload.data);
    },
    async updateLastSuccessArticleSearch(context, payload) {
      context.commit('setLastSuccessArticleSearch', payload.data);
    },
    async updateSearchedKeyword(context, payload) {
      // console.log('payload', payload)
      context.commit('setSearchedKeyword', payload);
    },
    markAsSeen(context, payload) {
      context.commit('seenMessages', '');
    },
    threadScrollToBottom(context) {
      context.commit('scrollToBottom', '');
    },
    printLog(context, payload) {
      if (['staging', 'localhost'].some(urlVal => window.location.hostname.includes(urlVal))) {
        if (payload?.text) {
          console.log(payload.text, ' ', moment().format('LT'), ' ', payload.log);
        } else {
          console.log(payload.log);
        }
      }
    },
  },
  getters: {
    // ... existing getters ...

    // Add reports getters if needed
    getReportById: state => reportId => {
      return state.reports.allReports.find(report => report.report_id === reportId);
    },

    getFavoriteReports: state => {
      return state.reports.favoriteReports;
    },

    getReportsByType: state => type => {
      return type === 'HS' ? state.reports.HSIncludedReports : state.reports.PSIncludedReports;
    },
  },
});
