// vueform.config.(js|ts)
import en from '@vueform/vueform/locales/en'
import {prefix as tailwind} from '@vueform/vueform/dist/tailwind'
import { defineConfig } from '@vueform/vueform'
import { getBaseUrls } from "@/utils/getBaseUrls";
const { teamsBaseURL } = getBaseUrls();


export default defineConfig({
  endpoints: {
    // uploadTempFile: {
    //   url:  `${teamsBaseURL}/upload_docs`,
    //   method: 'POST',
    // },

    uploadTempFile: async (value, el$) => {
      console.log('file ', value)
      const response = await el$.$vueform.services.axios.request({
        url: `${teamsBaseURL}/upload_docs`,
        method: 'POST',
        data: el$.form$.convertFormData({
          file: value,
        }),
        onUploadProgress: (e) => {
          el$.progress = Math.round((e.loaded * 100) / e.total)
        },
        cancelToken: el$.$vueform.services.axios.CancelToken.source().token,
      }) // errors are handled automatically
      console.log('response.data ', response.data)

      return response.data
    },
    removeTempFile:async (value, el$) => {
      console.log('value ', value)
      await el$.$vueform.services.axios.request({
        url: `${teamsBaseURL}/upload_docs`,
        method: 'DELETE',
        data: {url:value.file.url},
      }) // errors are handled automatically
    }
  },
  theme: tailwind('tw-'),
  locales: { en },
  locale: 'en',
})