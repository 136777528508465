<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 col-md-12 mt-2">
        <!-- <h5 class="text-dark text-center mt-2 mb-3">Sign in</h5> -->
        <form role="form" @submit.prevent="handleSubmit" class="text-start">
          <div class="mb-3">
            <input id="email" type="email" class="form-control" placeholder="Email" v-model="email" required />
          </div>
          <div class="mb-3">
            <input id="password" type="password" class="form-control" placeholder="Password" v-model="password"
              required />
          </div>

          <div class="form-check">
            <input class="form-check-input" type="checkbox" v-model="rememberMe" id="flexCheckDefault">
            <label class="form-check-label" for="flexCheckDefault">
              Remember me
            </label>
          </div>

          <div class="error text-sm text-danger">{{ error }}</div>

          <div class="text-center">
            <argon-button color="success" variant="gradient" full-width class="my-4 mb-2">
              <span v-if="isSigningIn">
                Signing in... <i class="fa fa-circle-o-notch fa-spin"></i>
              </span>
              <span v-else>
                Sign in
              </span>
            </argon-button>
          </div>
        </form>
        <!-- <div class="card border-0 mb-0">
          <div class="card-header bg-transparent">
            
          </div>
          <div class="card-body px-lg-5 pt-0">
            
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { inject, ref } from 'vue'
import useLogin from '@/composables/useLogin'
import useLogout from '@/composables/useLogout'
import ArgonButton from "@/components/ArgonButton.vue";
import { useRoute, useRouter } from 'vue-router'
import moment from 'moment';
import { projectFirestore } from '../../firebase/config';
import firebase from 'firebase/app'
import axios from 'axios';
import { useStore } from "vuex";
import Api from '../../views/admin/Api';


export default {
  name:'Login',
  components: {
    ArgonButton,
  },
  setup(props, context) {
    // refs

    document.title = 'Modal Support';
    const isSigningIn = ref(false)
    const email = ref('')
    const password = ref('')
    const route = useRoute();
    const router = useRouter();
    const rememberMe = ref(false)
    const swal = inject('$swal')
    const store = useStore()
    const { error, login, user } = useLogin()
    const { logout } = useLogout();

    const handleLogin = async () => {

    }

    const handleSubmit = async () => {

      isSigningIn.value = true
      await login(email.value, password.value)
      if (!error.value) {
        //context.emit('login')
        let timeStamp = null;
        if (rememberMe.value == true) {
          timeStamp = moment().add(30, 'days').valueOf();
        } else {
          timeStamp = moment().add(5, 'hours').valueOf();
        }
        // console.log('user', user)
        await projectFirestore.collection('Users').doc(user.value).get().then(async(doc) => {
          if (doc.exists) {
            let payload = {
              email:email.value,
              user_id:user.value,
              support_lib_id:doc.data().support_lib_id
            }
            if(doc?.data()?.user_type) {
              // console.log('doc?.data()?.user_type ', doc?.data()?.user_type)
              // localStorage.setItem('user_type', doc?.data()?.user_type)
            }
            const getFreshToken = async () => {
              const user = firebase.auth().currentUser;
              if (user) {
                return await user.getIdToken(true);
              } else {
                throw new Error("No user is signed in.");
              }
            }
            let loginToken = ''
            await getFreshToken()
              .then((token) => {
                // console.log('Fresh Token:', token)
                loginToken = token
              }).catch((error) => {
                console.error('Error fetching token:', error)
              });
            await Api.login(payload, loginToken).then((res) => {
              if(res?.data?.token) {
                localStorage.setItem('inbox-chat-token', res.data.token)
                localStorage.setItem('token-time', moment.utc().valueOf().toString())
              }
            }).catch((err) => {
              error.value = err.response?.data?.message ?? 'An error occured'
              logout();
              isSigningIn.value = false
              return;
            })
            async function handleLibrary(res, doc, payload) {
              if (res.exists && res.data()?.parent_lib_id) {
                const parent_lib_id = res.data().parent_lib_id;
                const libraryRefKey = res.data().library_refkey;
                const libraryDomain = res.data().library_domain;

                 const updateLocalStorage = async (libraryDomain) => {
                  await Api.getLoggedInUser().then((res) => {
                    if (res?.data?.user_details) {
                      store.state.agent_name = res?.data?.user_details?.agent_name;
                      store.state.agent_picture = res?.data?.user_details?.agent_picture;
                      store.state.display_name = res?.data?.user_details?.display_name;
                      store.state.is_away = res?.data?.user_details?.is_away;
                      localStorage.setItem('user_type', res?.data?.user_details?.agent_role)
                      store.state.agent_role = res?.data?.user_details?.agent_role
                      // let chat_enabled = res?.data?.user_details?.allowed_chat?.toString() == '1' ? 'true' : 'false';
                      // localStorage.setItem("chat_enabled", chat_enabled);
                      if (!!!res?.data?.user_details.agent_name.trim()) {
                        store.state.isAgentProfileNotCompleted = true
                        store.state.showAgentProfileAlert = true
                      } else {
                        store.state.isAgentProfileNotCompleted = false
                        store.state.showAgentProfileAlert = false
                      }
                    } else {
                      store.state.isAgentProfileNotCompleted = true
                      store.state.showAgentProfileAlert = true
                    }
                  }).catch((err) => {
                    store.state.showToast = {
                      title:'Error',
                      description:'An error occcured while getting LoggedInUser',
                      icon:'fa fa-exclamation-triangle',
                      time:moment().valueOf()
                    }
                  })
                  store.state.isUserPermissionsLoading = true
                  await Api.getPermissions({ref_uid:user.value, role: store.state.agent_role}).then((res) => {
                    if(res?.data?.permissions) {
                      res?.data?.permissions.forEach((el) => {
                        if(store.state.userPermissions[el.slug] !== undefined) {
                          store.state.userPermissions[el.slug] = el.is_enabled
                        }
                      })
                    }
                  }).catch((err) => {
                    store.state.showToast = {
                      title:'Error',
                      description:'An error occcured while getting Permissions',
                      icon:'fa fa-exclamation-triangle',
                      time:moment().valueOf()
                    }
                  }).finally(() => {
                    store.state.isUserPermissionsLoading = false
                  })

                  localStorage.setItem("support_Lib_id", doc.data().support_lib_id);
                  store.state.support_lib_id = doc.data().support_lib_id
                  localStorage.setItem("library_domain", libraryDomain);
                  if(res?.data()?.settings?.settings?.companyName) {
                    localStorage.setItem("company_name", res?.data()?.settings?.settings?.companyName);
                  }
                  localStorage.setItem("parent_lib_id", parent_lib_id);
                  localStorage.setItem('user_email', payload.email);
                  localStorage.setItem('user_id', payload.user_id);
                  store.state.user_id = payload.user_id
                  store.state.parent_lib_id = parent_lib_id;
                };

                const navigateUser = async () => {
                  if(route?.query.returnUrl) {
                    router.push(route?.query.returnUrl)
                  } else if (store.state.agent_role === 'agent') {
                    router.push({ name: 'ChatInboxClone' });
                  } else {
                    router.push({ name: 'Content' });
                  }
                };

                try {
                  if (libraryRefKey !== '') {
                    const refKeyResponse = await axios.get(`https://auth.locationapi.co/resources1?k=${libraryRefKey}`);
                    if (refKeyResponse?.data?.e === 1) {
                      await updateLocalStorage(libraryDomain);
                      await navigateUser();
                      return;
                    }
                  }
                  
                  if (libraryDomain !== '') {
                    const domainResponse = await axios.get(`https://auth.locationapi.co/resources1?d=${libraryDomain}`);
                    if (domainResponse?.data?.e === 1) {
                      await updateLocalStorage(libraryDomain);
                      await navigateUser();
                      return;
                    }
                  }

                  swal({
                    title: "Attention!",
                    text: "It looks like your subscription is no longer active. Please reach out to the team in Slack if you feel that this is a mistake or if you want to re-activate your account.",
                    icon: "warning",
                    showCancelButton: false,
                    confirmButtonText: "Close",
                    customClass: {
                      confirmButton: "btn bg-gradient-danger",
                      cancelButton: "btn bg-gradient-danger",
                    },
                    buttonsStyling: false,
                  });
                  isSigningIn.value = false;
                  logout();
                } catch (error) {
                  store.dispatch('printLog', {text:"Error fetching library info: ", log:error});
                  swal({
                    title: "Error",
                    text: "An error occurred while processing your request. Please try again later.",
                    icon: "error",
                    showCancelButton: false,
                    confirmButtonText: "Close",
                    customClass: {
                      confirmButton: "btn bg-gradient-danger",
                      cancelButton: "btn bg-gradient-danger",
                    },
                    buttonsStyling: false,
                  });
                  isSigningIn.value = false;
                  logout();
                }
              } else {
                localStorage.setItem("support_Lib_id", doc.data().support_lib_id);
                localStorage.setItem("library_domain", res.data().library_domain);
                localStorage.setItem('user_email', payload.email);
                localStorage.setItem('user_id', payload.user_id);
                store.state.support_lib_id = doc.data().support_lib_id; 
                store.state.user_id = payload.user_id;
                if (doc?.data()?.user_type === 'agent') {
                  router.push({ name: 'ChatInboxClone' });
                } else {
                  router.push({ name: 'Content' });
                }
              }
            }

            // Usage
            await projectFirestore.collection('SupportLibraries').doc(doc.data().support_lib_id).get().then(async (res) => {
              await handleLibrary(res, doc, payload);
            });

          }
        })
      }
      isSigningIn.value = false



    }

    return { email, password, handleSubmit, error, rememberMe, isSigningIn }
  },
}
</script>