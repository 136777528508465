<template>
  <!-- <navbar /> -->

  <div class="py-4 container-fluid">
    <div class="row">
      <div class="mx-auto col-lg-6 col-12">
        
        <div class="card">
          <div class="card-header pb-0 p-3">
            <h6 class="mb-0">Support Signin</h6>
          </div>
          <div class="card-body p-3">
            <form role="form" @submit.prevent="handleSubmit" class="text-start">
              <div class="mb-3">
                <input
                  id="email"
                  type="email"
                  class="form-control"
                  v-model="signin_email"
                  required
                />
              </div>

              <div class="error">{{ error }}</div>

              <div class="text-center">
                <argon-button
                  color="success"
                  variant="gradient"
                  full-width
                  class="my-4 mb-2"
                  >Continue</argon-button
                >
              </div>
              <!-- <argon-alert color="info" icon="ni ni-like-2 ni-lg">{{ hlpt_msg }}</argon-alert> -->
              <p class="text-center">{{ hlpt_msg }}</p>
            </form>
          </div>
        </div>

      </div>
    </div>
  </div> 
  
  <app-footer />
</template>
<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
/* import Navbar from "@/components/custom/admin/Navbar.vue"; */
import AppFooter from "@/components/custom/Footer.vue";
import setNavPills from "@/assets/js/nav-pills.js";
import ArgonButton from "@/components/ArgonButton.vue";
import { projectAuth, timestamp } from '@/firebase/config'
/* import getUser from "@/composables/getUser"
const { user } = getUser() */

export default {
  name: "Hlpt",

  components: {
    /* ArgonSwitch, */
    /* Navbar, */
    AppFooter,
    ArgonButton,
  },
  setup() {
    const signin_email = ref('')
    const hlpt_msg = ref('')

    const router = useRouter()

    const handleSubmit = async () => {
      // console.log("Called Handlesubmit!");

      var actionCodeSettings = {
        url: 'https://app.modalsupport.com/admin',
        handleCodeInApp: true,
      };
      
      projectAuth.sendSignInLinkToEmail(signin_email.value, actionCodeSettings)
      .then(() => {
        // console.log("Email Link is sent!");
        hlpt_msg.value = "An email with signin link has been sent to your email address!";
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        window.localStorage.setItem('emailForSignIn11', '');        
        // ...
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        this.$store.dispatch('printLog', {text:"Error Message: ", log:errorMessage});
        hlpt_msg.value = errorMessage;
      });
    }

    return { signin_email, handleSubmit, hlpt_msg }
  },
  data() {
    return {};
  },
  mounted() {
    this.$store.state.showSidenav = false;
    //this.$store.state.showNavbar = false;
    this.$store.state.showFooter = false;
    setNavPills();
  },
  beforeUnmount() {
    this.$store.state.showSidenav = true;
    //this.$store.state.showNavbar = true;
    this.$store.state.showFooter = true;
    if (this.$store.state.isPinned === false) {
      const sidenav_show = document.querySelector(".g-sidenav-show");
      sidenav_show.classList.remove("g-sidenav-hidden");
      sidenav_show.classList.add("g-sidenav-pinned");
      this.$store.state.isPinned = true;
    }
  },

};
</script>
